import { CMS_THEME } from 'src/common/enums';

interface ImageContentStyleSettings {
  color: CMS_THEME;
  contentPosition: 'left' | 'right' | undefined;
  verticalAlignment: 'center' | 'top' | 'bottom';
}

export interface ImageContentBase {
  styleSettings: ImageContentStyleSettings;
}

interface IDefaultSettings {
  entity_buttons: {
    primary_button: string;
  };
  entity_heading: {
    element: keyof JSX.IntrinsicElements;
    style: string;
  };
  entity_subheading: {
    element: keyof JSX.IntrinsicElements;
    style: string;
  };
  entity_variant: {
    content_position: 'right' | 'left' | undefined;
    vertical_alignment: 'center' | 'top' | 'bottom';
    theme: CMS_THEME;
  };
}

export const DefaultSettings: IDefaultSettings = {
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_heading: {
    element: 'h2' as keyof JSX.IntrinsicElements,
    style: 'pds-text-title-md pds-font-normal pds-tracking-[initial]',
  },
  entity_subheading: {
    element: 'h3' as keyof JSX.IntrinsicElements,
    style: 'pds-text-title-sm pds-font-normal pds-tracking-[initial]',
  },
  entity_variant: {
    content_position: 'right',
    vertical_alignment: 'center',
    theme: CMS_THEME.LIGHT,
  },
};

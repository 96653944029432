import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@purinanbm/pds-ui';
import { navigate } from 'gatsby';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { mdiMailOutline, mdiPackage2Outline } from 'src/assets/icons/mdiIcons';
import { RewardCard } from 'src/components/card/RewardCard';
import { User, useAuth } from 'src/hooks/useAuth';
import {
  LOYALTY_MUTATIONS,
  LOYALTY_QUERIES,
  useLoyaltyMutation,
  useLoyaltyQuery,
} from 'src/hooks/useLoyaltyService';
import {
  AddressValidation,
  AddressValidationStatus,
  generateAddressValidationErrorMessage,
  useValidateAddress,
} from 'src/services/Google/validateAddress';
import { PostRewardsRequestBody } from 'src/services/loyalty/data-contracts';
import { Reward } from '../../../services/loyalty/types';
import PurinaPerksFooter from '../PurinaPerksFooter';
import { PURINA_PERKS_ROUTES } from '../PurinaPerksLayout';
import { onCheckoutError } from '../helpers';
import { RewardCheckoutAddressConfirmModal } from './RewardCheckoutAddressConfirmModal';
import RewardCheckoutFields from './RewardCheckoutFields';
import RewardCheckoutSummary from './RewardCheckoutSummary';
import { RewardDivider } from './RewardDivider';
import {
  RewardDigitalFormType,
  RewardPhysicalFormType,
  rewardDigitalCheckoutSchema,
  rewardPhysicalCheckoutSchema,
} from './types';

const createRewardCheckoutRequestBody = (
  formData: RewardPhysicalFormType | RewardDigitalFormType,
  user: User,
  item: Reward,
): PostRewardsRequestBody => {
  const transactionNumber = crypto.randomUUID();

  if (formData.type === 'PHYSICAL') {
    return {
      email: formData.email,
      pid: item.pid,
      firstName: formData.first,
      lastName: formData.last,
      transactionNumber,
      state: formData.state,
      city: formData.city,
      address1: formData.street1,
      address2: formData.street2,
      postalCode: formData.zip,
    };
  }

  if (formData.type === 'DIGITAL') {
    return {
      email: formData.email,
      pid: item.pid,
      firstName: user.name,
      lastName: user.lastName,
      transactionNumber,
    };
  }

  throw new Error('Invalid reward type');
};

const RewardCheckout: React.FC<{ item: Reward }> = ({ item }) => {
  const checkoutSummaryRef = useRef<InferRef<typeof RewardCheckoutSummary>>(null);
  const addressValidation = useRef<AddressValidation | undefined>();
  const isAddressConfirmed = useRef<boolean>(false);

  const [error, setError] = useState<Error | undefined>();
  const [isAddressInvalidModalOpen, setIsAddressInvalidModalOpen] = useState<boolean>();
  const [isAddressConfirmModalOpen, setIsAddressConfirmModalOpen] = useState<boolean>();

  const { user } = useAuth();
  const { enableCatalogAddressValidation } = useFlags();

  const userAnsiraId = user?.ansiraUuid || '';

  const { data: customer } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_CUSTOMERS_DETAIL,
    { customerId: userAnsiraId },
    { enabled: Boolean(userAnsiraId) },
  );

  const postRewardMutation = useLoyaltyMutation(LOYALTY_MUTATIONS.POST_REWARDS_CREATE, {
    rewardId: String(item.id),
    customerId: user?.ansiraUuid || '',
  });

  const validateAddressMutation = useValidateAddress();

  const { handleSubmit, control, formState, setValue, reset, getValues, setFocus } = useForm<
    RewardPhysicalFormType | RewardDigitalFormType
  >({
    resolver: yupResolver(
      item.type === 'PHYSICAL' ? rewardPhysicalCheckoutSchema : rewardDigitalCheckoutSchema,
    ),
    defaultValues: { email: user?.email },
  });

  const formData = getValues();

  // Allows for the form type to be determined from the form data
  useEffect(() => {
    setValue('type', item.type);
  }, [setValue, item.type]);

  // Ensure address is re-validated after the form is updated
  useEffect(() => {
    if (!formState.isDirty) return;

    isAddressConfirmed.current = false;
  }, [formState]);

  // Focus address when invalid address modal is closed
  useEffect(() => {
    if (isAddressInvalidModalOpen === false) {
      setFocus('street1');
    }
  }, [isAddressInvalidModalOpen, setFocus]);

  // Focus submit button after the address confirm modal is closed
  useEffect(() => {
    if (isAddressConfirmModalOpen === false) {
      checkoutSummaryRef.current?.focusSubmitButton();
    }
  }, [isAddressConfirmModalOpen]);

  // Focus submit button if a form submission fails
  useEffect(() => {
    if (!formState.isSubmitting) {
      checkoutSummaryRef.current?.focusSubmitButton();
    }
  }, [formState.isSubmitting]);

  const onSubmit = async (submittedFormData: RewardPhysicalFormType | RewardDigitalFormType) => {
    setError(undefined);

    if (!user?.ansiraUuid) {
      setError(new Error('Unable to find user'));
      return;
    }

    const isPhysical = submittedFormData.type === 'PHYSICAL';

    if (isPhysical && !isAddressConfirmed.current && enableCatalogAddressValidation) {
      let validationStatus = AddressValidationStatus.ACCEPT;

      try {
        const { validation, status } = await validateAddressMutation.mutateAsync({
          city: submittedFormData.city,
          state: submittedFormData.state,
          street1: submittedFormData.street1,
          street2: submittedFormData.street2,
          zip: submittedFormData.zip,
        });

        validationStatus = status;
        addressValidation.current = validation;
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        console.error(e);

        sendIt({
          event: 'error_occurred',
          eventParams: {
            error_field: 'reward submission',
            error_code: 'address validation',
            error_name: generateAddressValidationErrorMessage(e),
            error_feature: 'reward-checkout',
          },
        });
      }

      if (validationStatus === AddressValidationStatus.FIX) {
        setIsAddressInvalidModalOpen(true);
        return;
      }

      if (validationStatus === AddressValidationStatus.CONFIRM) {
        setIsAddressConfirmModalOpen(true);
        return;
      }
    }

    try {
      const requestBody = createRewardCheckoutRequestBody(submittedFormData, user, item);
      const response = await postRewardMutation.mutateAsync(requestBody);

      if (!response.success) {
        throw new Error(JSON.stringify(response));
      }

      const currentPoints = customer?.customer?.loyaltyPoints;

      sendIt({
        event: 'spend_virtual_currency',
        eventParams: {
          item_id: item.name,
          item_code: item.pid,
          current_balance: currentPoints ? String(currentPoints - item.points) : '[not set]',
          value: String(item.points),
          method: 'redeem reward',
          item_category: isPhysical ? 'physical reward' : 'digital reward',
        },
      });

      await navigate(PURINA_PERKS_ROUTES.CheckoutSuccess, { state: { reward: item } });
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
      const errorMessage = e?.error?.detail || e?.error?.message || e.message || 'Unknown error';
      setError(new Error(errorMessage));

      sendIt({
        event: 'error_occurred',
        eventParams: {
          error_field: 'reward submission',
          error_code: 'capillary error',
          error_name: errorMessage,
          error_feature: 'reward-checkout',
        },
      });
    }
  };

  return (
    <>
      <form
        className="pds-flex pds-flex-col lg:pds-grid lg:pds-grid-cols-12 lg:pds-gap-4"
        onSubmit={handleSubmit(onSubmit, onCheckoutError)}
      >
        <div className="pds-col-span-8">
          <RewardCard withPageHeading iconPath={mdiMailOutline} title="Send Confirmation Email to:">
            <RewardCheckoutFields item={item} control={control} />
            <RewardDivider title="Shipping Method" icon={mdiPackage2Outline} />
            {item.type === 'PHYSICAL' ? (
              <div className="pds-flex pds-flex-col pds-rounded pds-border pds-border-surface-line pds-p-4">
                <div className="pds-flex pds-justify-between">
                  <h3 className="pds-text-body-md pds-font-semibold">Standard Shipping</h3>
                  <span className="pds-font-semibold pds-text-primary">FREE</span>
                </div>
                Arrives in 10-14 Business Days
              </div>
            ) : (
              <div className="pds-flex pds-flex-col pds-rounded pds-border pds-border-surface-line pds-p-4">
                <div className="pds-flex pds-justify-between">
                  <h3 className="pds-text-title-sm pds-font-semibold">Digital Delivery</h3>
                  <span className="pds-font-semibold pds-text-primary">FREE</span>
                </div>
                If you selected a digital gift (like a gift card), it may take up to two business
                days for your reward to reach you.
              </div>
            )}
          </RewardCard>

          <PurinaPerksFooter />
        </div>
        <RewardCheckoutSummary
          ref={checkoutSummaryRef}
          submissionIsLoading={formState.isSubmitting}
          error={error}
          item={item}
        />
      </form>

      <Dialog
        open={isAddressInvalidModalOpen}
        onOpenChange={isOpen => setIsAddressInvalidModalOpen(isOpen)}
      >
        <DialogContent className="pds-px-5 pds-pb-5 pds-pt-5.5">
          <DialogClose />

          <DialogTitle className="pds-text-title-md">Invalid Address</DialogTitle>

          <DialogDescription className="pds-mb-5">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            We couldn't confirm your address details. Please ensure you entered all information
            correctly, including street numbers and zip codes.
          </DialogDescription>

          <div className="pds-flex pds-justify-center">
            <Button
              width="full"
              buttonStyle="solid"
              buttonColor="neutral"
              onClick={() => setIsAddressInvalidModalOpen(false)}
            >
              Re-Enter Address
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {isAddressConfirmModalOpen && addressValidation.current && formData.type === 'PHYSICAL' && (
        <RewardCheckoutAddressConfirmModal
          formData={formData}
          addressValidation={addressValidation.current}
          open={isAddressConfirmModalOpen}
          onOpenChange={open => setIsAddressConfirmModalOpen(open)}
          onClickBack={() => setIsAddressConfirmModalOpen(false)}
          onClickSelect={selectedAddress => {
            setValue('street1', selectedAddress.street1);
            setValue('street2', selectedAddress.street2);
            setValue('city', selectedAddress.city);
            setValue('state', selectedAddress.state);
            setValue('zip', selectedAddress.zip);

            // Ensures the form is not dirty after manually setting the values
            reset(getValues(), {
              keepIsSubmitSuccessful: true,
              keepIsSubmitted: true,
              keepSubmitCount: true,
            });

            isAddressConfirmed.current = true;
            setIsAddressConfirmModalOpen(false);
            handleSubmit(onSubmit, onCheckoutError)();
          }}
        />
      )}
    </>
  );
};

export default RewardCheckout;

import { css } from '@linaria/core';
import { PageProps, graphql } from 'gatsby';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Stack } from 'react-bootstrap';
import StorageFaqSection from 'src/cms-components/StorageFaqSection';
import Layout from 'src/components/Layout';
import Seo from 'src/components/Seo';
import MediaImage from 'src/components/media/MediaImage';
import RichText from 'src/components/rich_text/RichText';
import { getCmsComponent } from 'src/utils/cmsComponentHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from 'src/utils/metatagHelpers';

const richText = css`
  max-width: 75rem; // 1200px
`;

interface NodeFaqProps extends FaqContent {
  title: string;
  relationships: {
    faq_sections: IStorageFaqSection[];
    featured_image?: MediaImage;
  };
}

interface DataProps {
  node: NodeFaqProps;
  translations: Translations;
}

const FaqTemplate: React.FC<PageProps<DataProps>> = function ({ data: { node, translations } }) {
  const {
    subnav,
    faq_sections: faqSections,
    featured_image: featuredImage = {} as MediaImage,
  } = node.relationships;
  const hasImage = !isEmpty(featuredImage);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {subnav && getCmsComponent({ node: subnav, index: 0 })}
      <Stack className="gap-xl-5 gap-3 py-5 container-xxl">
        <Stack className="gap-xl-5 gap-3 text-center container-xxl align-items-center">
          <h1 className="pds-text-display-sm">{node.title}</h1>
          {node.body?.processed && <RichText className={richText} body={node.body?.processed} />}
        </Stack>
        {hasImage && (
          <Stack>
            <MediaImage {...featuredImage} loading="eager" />
          </Stack>
        )}
        <Stack className={`mt-0 ${hasImage ? 'mt-xl-5' : ''}`}>
          {faqSections.map(faqSection => (
            <StorageFaqSection node={faqSection} key={faqSection.id} />
          ))}
        </Stack>
      </Stack>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeFaq(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      body {
        processed
      }
      ...Metatag
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        subnav: field_subnav_storage {
          type: __typename
          ...StorageSubnav
        }
        faq_sections: field_faq_sections {
          ...StorageFaqSection
        }
        featured_image: field_image {
          ...MediaImage
          relationships {
            field_media_image {
              gatsbyImage(aspectRatio: 2, width: 1600, placeholder: NONE)
            }
          }
        }
      }
    }
    translations: allNodeFaq(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default FaqTemplate;

import * as React from 'react';
import { CMS_OVERLAY, CMS_THEME } from '../../common/enums';
import { StorageHeroCardStyles } from './StorageHeroCardStyles';
import HeroBgSection from './components/HeroBgSection';
import HeroButtons from './components/HeroButtons';
import HeroCardWrapper from './components/HeroCardWrapper';
import HeroDesktopForgroundImage from './components/HeroDesktopForgroundImage';
import HeroText from './components/HeroText';
import { useIsMobileDevice } from './hooks/useIsMobileDeviceHero';
import { HeroDefaultSettings } from './types';

interface StorageCardProps {
  node: IStorageHeroCard;
}

// eslint-disable-next-line complexity
const StorageHeroCard: React.FC<StorageCardProps> = function ({ node: heroCard }) {
  const isMobileDevice = useIsMobileDevice();
  const settings = Object.assign(HeroDefaultSettings, heroCard?.behaviors || {});
  const variant = settings.entity_variant.hero_variant_type;
  const isQrHero = heroCard.relationships?.secondary_image?.field_category?.some(
    category => category === 'qr',
  );
  const hideQrCode = Boolean(isQrHero && isMobileDevice);
  const direction = settings?.entity_variant?.content_position;
  const cmsTheme = heroCard.behaviors?.entity_variant?.theme || CMS_THEME.LIGHT;
  const cmsOverlayTheme = heroCard.behaviors?.entity_variant?.overlay || CMS_OVERLAY.LIGHT;
  const foregroundImageAlignment = settings?.entity_variant?.foreground_image_alignment ?? 'left';
  const heroImage = heroCard.relationships.image?.relationships?.field_media_image?.gatsbyImage;
  const foregroundImage =
    heroCard?.relationships?.secondary_image?.relationships?.field_media_image.gatsbyImage;
  const logoImage = heroCard.relationships.logo;

  const lazyLoad = settings?.entity_variant?.load_eagerly ? 'eager' : 'lazy';
  const styleSettings = {
    theme: cmsTheme,
    textDirection: direction,
    foregroundAlignment: foregroundImageAlignment,
    overlayTheme: cmsOverlayTheme,
    variant,
  };
  const { mainContainer, heroContent, imgContentContainer } = StorageHeroCardStyles(styleSettings);

  return (
    <div id={heroCard.component_id} data-id={heroCard.drupal_id} className={mainContainer()}>
      <div className="pds-relative pds-h-full">
        <HeroBgSection
          hideQrCode={hideQrCode}
          heroCard={heroCard}
          heroImage={heroImage}
          lazyLoad={lazyLoad}
          foregroundImage={foregroundImage}
          styleSettings={styleSettings}
        />
        <div className={heroContent({ className: 'heroContent' })}>
          <div className={imgContentContainer({ className: 'pds-max-w-[1300px]' })}>
            {foregroundImage && !hideQrCode ? (
              <HeroDesktopForgroundImage
                heroCard={heroCard}
                lazyLoad={lazyLoad}
                foregroundImage={foregroundImage}
                styleSettings={styleSettings}
              />
            ) : (
              <div className="pds-hidden pds-h-full pds-w-full lg:pds-block" />
            )}
            <HeroCardWrapper styleSettings={styleSettings}>
              <HeroText styleSettings={styleSettings} heroCard={heroCard} logoImage={logoImage}>
                <HeroButtons isQrHero={isQrHero} hideQrCode={hideQrCode} heroCard={heroCard} />
              </HeroText>
            </HeroCardWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageHeroCard;

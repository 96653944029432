import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { imageContentStyles } from '../styles';
import { ImageContentBase } from '../types';

const ImgContentImage: React.FC<{ media_image: MediaImage } & ImageContentBase> = ({
  media_image,
  styleSettings,
}) => {
  const { thumbnail } = imageContentStyles(styleSettings);
  return (
    <div className="pds-flex-1">
      <GatsbyImage
        alt={media_image?.field_media_image?.alt}
        image={media_image?.relationships?.field_media_image?.gatsbyImage}
        className={thumbnail()}
      />
    </div>
  );
};

export default ImgContentImage;

// TODO: This is very similar to the use mobile device hook, but it is specifically for the hero component.
// However I stripped it of all unnecessary code related to platform.
// Can we just have a useMobileDevice hook with generics
import { useEffect, useState } from 'react';

export const useIsMobileDevice = (): boolean => {
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(true);

  useEffect(() => {
    const isMobileTest = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (!isMobileTest) {
      setIsMobileDevice(false);
      return;
    }

    setIsMobileDevice(true);
  }, []);

  return isMobileDevice;
};

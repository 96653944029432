import Icon from '@mdi/react';
import AppStoreBanner from 'gatsby-plugin-app-banner/AppStoreBanner';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { mdiClose } from 'src/assets/icons/mdiIcons';
import Link from 'src/components/Link';
import RichText from 'src/components/rich_text/RichText';
import { theme } from 'src/theme/theme';
import { getMedia } from 'src/utils/mediaHelpers';

interface StorageSmartAppBannerProps {
  node: IStorageSmartAppBanner;
}

const DEFAULT_URL = 'https://mypurina.onelink.me/z05v/purinasmartbannerlegacy';

const StorageSmartAppBanner: React.FC<StorageSmartAppBannerProps> = ({ node }) => {
  const { t } = useTranslation();
  const cmsTheme = node?.behaviors?.entity_variant?.theme;

  return (
    <AppStoreBanner
      dismissDuration={3 * 60000} // 3 minutes in milliseconds
    >
      {/* @ts-ignore - A11y Requirement */}
      {(handleDismiss, key) => (
        <section
          key={key}
          aria-label="myPurina app"
          className="pds-w-full pds-border-y pds-border-b-[#dee2e6] pds-border-t-[#dee2e6] pds-bg-white"
        >
          <div className="pds-m-auto pds-flex pds-max-w-[493px] pds-items-center pds-justify-between pds-p-3">
            <div className="pds-flex pds-items-center pds-gap-[13px]">
              <button
                className="pds-border-none pds-bg-transparent"
                type="button"
                aria-label={t('Close')}
                onClick={handleDismiss}
              >
                <Icon path={mdiClose} size={0.6} color={theme.common.gray[600]} />
              </button>
              {getMedia({
                alt: '',
                media: node.relationships.logo,
                className: 'pds-h-[48px] pds-w-[48px]',
                objectFit: 'contain',
                imgClassName: 'pds-object-cover',
                loading: 'eager',
              })}
              <div className="pds-max-w-[165px] md:pds-max-w-max">
                <span className="pds-text-md pds-font-bold">{node.title}</span>
                <RichText
                  cmsTheme={cmsTheme}
                  className="pds-mb-0 [&_>p]:pds-text-sm"
                  body={node.body.processed ?? ''}
                />
              </div>
            </div>

            <AnalyticsPoint
              type="component"
              category="banner click"
              action="mypurina app"
              typeLabel="[not set]"
              label="[not set]"
            >
              <Link
                to={node.link?.url ?? DEFAULT_URL}
                hideExternalIcon
                rel={node.link?.options?.attributes?.rel ?? 'noreferrer'}
                target={node.link?.options?.attributes?.target}
                className="js-track pds-block pds-w-max pds-cursor-pointer pds-rounded-[2.5rem] pds-bg-[#0071e3] pds-px-4 pds-py-2 pds-font-sans pds-text-body-sm pds-uppercase pds-text-white pds-no-underline hover:pds-border-transparent hover:pds-bg-[#0077ed] hover:pds-text-white focus:pds-border-transparent focus:pds-bg-[#0077ed] focus:pds-text-white focus:pds-outline-none focus-visible:pds-shadow-[0_0_0_3px_#fff,0_0_0_5px_#0071e3]"
              >
                {node.link?.title}
              </Link>
            </AnalyticsPoint>
          </div>
        </section>
      )}
    </AppStoreBanner>
  );
};

export default StorageSmartAppBanner;

import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mdi/react';
import { Button } from '@purinanbm/pds-ui';
import { AxiosError } from 'axios';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import Cookies from 'js-cookie';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import {
  FieldError,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mdiAddOutline } from 'src/assets/icons/mdiIcons';
import { getRecaptchaToken } from 'src/utils/formHelpers';
import { AnsiraUserProps, ansiraUserPost } from '../../utils/clientRequests';
import Alert from '../alert/Alert';
import RichText from '../rich_text/RichText';
import ControlledPets from './components/ControlledPets';
import RecaptchaDisclaimer from './components/RecaptchaDisclaimer';
import CheckboxField from './new_components/CheckboxField';
import InputField from './new_components/InputField';
import { ProfileFormTypes, createProfileSchema } from './utils/profile';

interface FormProps {
  sourceCode: string;
  settings: IGlobalFormSettings;
  language: Languages;
}

const ProfileForm: React.FC<FormProps> = function ({ sourceCode, settings, language }) {
  const { t } = useTranslation();
  const profileSchema = createProfileSchema(t);

  const form = useForm<ProfileFormTypes>({
    defaultValues: {
      sourceCode: {
        keyName: sourceCode,
      },
      firstName: '',
      lastName: '',
      email: '',
      postalCode: '',
      dogPets: [],
      catPets: [],
      optIn: false,
      language: {
        keyName: 'en',
      },
    },
    resolver: yupResolver(profileSchema),
  });

  const { handleSubmit, reset, control } = form;
  const {
    fields: catFields,
    append: appendCat,
    remove: removeCat,
  } = useFieldArray<ProfileFormTypes>({
    control,
    name: 'catPets',
  });
  const {
    fields: dogFields,
    append: appendDog,
    remove: removeDog,
  } = useFieldArray<ProfileFormTypes>({
    control,
    name: 'dogPets',
  });

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [apiError, setApiError] = React.useState('');
  const apiRef = React.useRef<HTMLDivElement>(null);

  const handleAddPet = (type: 'CAT' | 'DOG') => {
    const newPet: ProfileFormTypes['dogPets'][number] = {
      petType: {
        keyName: type,
      },
      name: '',
      birthDate: '',
    };
    if (type === 'DOG') {
      appendDog(newPet);
    } else {
      appendCat(newPet);
    }
  };

  const onSubmit: SubmitHandler<ProfileFormTypes> = async formData => {
    const { catPets, dogPets, postalCode, ...rest } = formData;
    const dogCount = dogPets.length || 0;
    const catCount = catPets.length || 0;
    let ownerShipPlan = 'NEITHER';
    if (dogCount && catCount) {
      ownerShipPlan = 'BOTH';
    }
    if (dogCount && !catCount) {
      ownerShipPlan = 'DOG';
    }
    if (catCount && !dogCount) {
      ownerShipPlan = 'CAT';
    }
    const data = {
      ...rest,
      pets: [...dogPets, ...catPets],
      petOwnershipPlan: { keyName: ownerShipPlan },
      address: { postalCode },
      RecaptchaToken: '',
      language: { keyName: language },
    } as AnsiraUserProps;

    setSubmitting(true);
    data.RecaptchaToken = await getRecaptchaToken();
    const userData = await ansiraUserPost({ data });
    if (!userData) {
      setApiError(t('Something went wrong'));
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    if (userData instanceof AxiosError) {
      setApiError(userData.response?.data?.message || t('Something went wrong'));
      setSubmitting(false);
      setTimeout(() => {
        apiRef.current?.focus();
      }, 500);
      return;
    }

    //  GA Event ---------------------------------
    if (navigator.onLine) {
      const submitAnalyticsForm = {
        event: 'sign_up',
        eventCategory: 'ansira_profile_form_sucess',
        eventParams: {
          form_type: 'ansira_profile_form',
          form_id: sourceCode,
          form_name: sourceCode,
          form_variation: sourceCode,
          method: 'on_page',
          email_optin: data.optIn.toString(),
        },
      };
      sendIt(submitAnalyticsForm);
    }

    Cookies.set('profiles_uuid', userData.uuid);
    setSubmitted(true);
    reset();
    setSubmitting(false);
  };

  //  GA Error Event ---------------------------------
  const onError: SubmitErrorHandler<ProfileFormTypes> = validationErrors => {
    const errorsArray = Object.entries(validationErrors);
    (errorsArray as [string, FieldError][]).forEach(([key, error]) => {
      const sendErrorToGA = ([errorKey, fieldError]: [string, FieldError]) => {
        const { message, type } = fieldError;
        const submitAnalyticsErrorForm = {
          event: 'error_occurred',
          eventCategory: 'ansira_profile_form_error',
          eventParams: {
            form_type: 'ansira_profile_form',
            form_id: sourceCode,
            form_name: sourceCode,
            error_code: type?.toString() ?? 'ERROR',
            error_name: errorKey,
            module_name: errorKey,
            error_feature: message as string,
          },
        };
        sendIt(submitAnalyticsErrorForm);
      };
      sendErrorToGA([key, error]);
    });
  };
  if (submitting) {
    return (
      <Row className="pds-justify-center">
        <Col className="pds-text-center">
          <Spinner animation="border" role="status">
            <span className="pds-sr-only">{t('Submitting')}...</span>
          </Spinner>
          <span className="pds-ms-4">{t('Please Wait')}...</span>
        </Col>
      </Row>
    );
  }

  if (submitted) {
    return (
      <Row>
        <Col className="text-center">
          <h3 className="pds-text-title-md">{settings.success_title}</h3>
          <Alert role="alert" variant="success" className="my-3">
            <RichText body={settings.success_response} />
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <FormProvider {...form}>
      <Form
        className="pds-px-[12px] sm-bt:pds-px-0"
        onSubmit={handleSubmit(onSubmit, onError)}
        autoComplete="off"
        noValidate
      >
        <Row className="pds-gx-3 pds-gy-3">
          {apiError && (
            <Alert variant="error" role="alert" tabIndex={-1} ref={apiRef}>
              {apiError}
            </Alert>
          )}
          <Col xs={12} md={6}>
            <InputField
              hideAsterisk={false}
              name="firstName"
              required
              autoComplete="given-name"
              label={t('First Name')}
              maxLength={60}
            />
          </Col>
          <Col xs={12} md={6}>
            <InputField
              hideAsterisk={false}
              name="lastName"
              required
              autoComplete="family-name"
              label={t('Last Name')}
              maxLength={60}
            />
          </Col>{' '}
          <Col xs={12}>
            <InputField
              hideAsterisk={false}
              name="email"
              type="email"
              required
              autoComplete="email"
              label={t('E-mail Address')}
              placeholder={t('example@mail.com')}
              maxLength={128}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <InputField
              hideAsterisk={false}
              name="postalCode"
              label={t('Zip Code')}
              required
              autoComplete="postal-code"
              maxLength={5}
            />
          </Col>
          <Col xs={12}>
            <div className="pds-rounded pds-border pds-p-4 sm-bt:pds-border-none sm-bt:pds-p-0">
              <fieldset>
                <legend>{t('My Pets')}</legend>
                <Row className="!pds-mb-4.5 pds-gx-3 pds-gy-3 sm-bt:pds-gx-2">
                  <Col xs="auto" className="pds-flex pds-flex-wrap pds-gap-4">
                    <Button
                      type="button"
                      buttonStyle="outlined"
                      buttonColor="neutral"
                      onClick={() => handleAddPet('DOG')}
                      className="pds-flex"
                    >
                      <Icon path={mdiAddOutline} size={1} className="pds-mr-2" /> {t('Add Dog')}
                    </Button>
                    <Button
                      type="button"
                      buttonStyle="outlined"
                      buttonColor="neutral"
                      onClick={() => handleAddPet('CAT')}
                      className="pds-flex"
                    >
                      <Icon path={mdiAddOutline} size={1} className="pds-mr-2" /> {t('Add Cat')}
                    </Button>
                  </Col>
                </Row>
                <div className="pds-space-y-3">
                  <ControlledPets
                    fields={dogFields}
                    remove={removeDog}
                    petType="dog"
                    key="dogPets"
                  />
                </div>
                <div className="pds-mt-3 pds-space-y-3">
                  <ControlledPets
                    fields={catFields}
                    remove={removeCat}
                    petType="cat"
                    key="catPets"
                  />
                </div>
              </fieldset>
            </div>
          </Col>
          <Col xs={12} className="!pds-mt-4">
            <CheckboxField label={<RichText body={settings.optin_label} />} name="optIn" required />
          </Col>
          <Col xs={12} className="!pds-mt-4">
            <RecaptchaDisclaimer />
          </Col>
          <Col xs={12} className="pds-text-center">
            <Button
              buttonColor="neutral"
              className="js-track pds-mx-auto pds-w-full pds-text-center md-bt:pds-max-w-[400px]"
              type="submit"
              disabled={submitting}
            >
              {submitting ? `${t('Please wait')}...` : t('Join Now')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default ProfileForm;

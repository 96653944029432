import { Checkbox, ICheckbox } from '@purinanbm/pds-ui';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { FieldErrorDisplay } from './FieldError';

type Props = Omit<ICheckbox, 'name' | 'id'> & {
  className?: string;
  required?: boolean;
  name: string;
  id?: string;
  control?: Control<any>;
};

const CheckboxField = ({ required = false, control, label, name, ...rest }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className="pds-flex pds-flex-col">
      <Checkbox
        label={label}
        position="top"
        onCheckedChange={value => field.onChange(value)}
        aria-invalid={!!error}
        data-error={!!error}
        aria-required={required}
        className="[&>button:focus]:pds-outline-focus [&>button]:pds-mt-2"
        {...field}
        aria-describedby={`${name}-message`}
        {...rest}
        id={name}
      />
      {error && <FieldErrorDisplay error={error} id={`${name}-message`} />}
    </div>
  );
};

export default CheckboxField;

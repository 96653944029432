import Icon from '@mdi/react';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@purinanbm/pds-ui';
import React from 'react';
import { mdiAlertCircleOutline, mdiCheckSquareSolid } from 'src/assets/icons/mdiIcons';
import Alert from 'src/components/alert/Alert';
import { Address, AddressValidation } from 'src/services/Google/validateAddress';
import { RewardPhysicalFormType } from './types';

type Props = {
  addressValidation: AddressValidation;
  formData: RewardPhysicalFormType;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClickBack: () => void;
  onClickSelect: (confirmedAddress: Address) => void;
};

const AddressRadioCard: React.FC<{
  id: string;
  address: Address;
  title: string;
  onClick: (address: Address) => void;
}> = ({ id, address, onClick, title }) => {
  return (
    <label
      htmlFor={id}
      className={[
        'pds-flex pds-cursor-pointer pds-justify-between pds-rounded pds-border pds-border-solid pds-border-surface-line pds-p-4',
        'has-[:checked]:pds-rounded-xl has-[:checked]:pds-border-thick has-[:checked]:pds-border-neutral has-[:checked]:pds-bg-surface-text/[.05] has-[:checked]:pds-p-[15px]',
        'has-[:focus-visible]:pds-outline has-[:focus-visible]:pds-outline-2 has-[:focus-visible]:pds-outline-focus',
      ].join(' ')}
    >
      <input
        className="radio pds-sr-only"
        type="radio"
        id={id}
        name="address"
        value={id}
        onClick={() => onClick(address)}
      />

      <div>
        <div className="pds-font-bold">{title}</div>
        <div className="pds-text-surface-textSubdued">{address.street1}</div>

        {address.street2 && <div className="pds-text-surface-textSubdued">{address.street2}</div>}

        <div className="pds-text-surface-textSubdued">
          {address.city} {address.state}, USA {address.zip}{' '}
        </div>
      </div>

      <Icon
        path={mdiCheckSquareSolid}
        size={1}
        className="pds-hidden  [.radio:checked~&]:pds-block"
        aria-hidden
      />
    </label>
  );
};

export const RewardCheckoutAddressConfirmModal: React.FC<Props> = ({
  formData,
  addressValidation,
  open,
  onOpenChange,
  onClickBack,
  onClickSelect,
}) => {
  const [showError, setShowError] = React.useState(false);
  const [confirmedAddress, setConfirmedAddress] = React.useState<Address | undefined>();

  const postalAddressObj = addressValidation.result.address.postalAddress;

  const validatedAddress: Address = {
    city: postalAddressObj.locality,
    state: postalAddressObj.administrativeArea,
    street1: postalAddressObj.addressLines[0],
    street2: postalAddressObj.addressLines[1],
    zip: postalAddressObj.postalCode,
  };

  const enteredAddress: Address = {
    city: formData.city,
    state: formData.state,
    street1: formData.street1,
    street2: formData.street2,
    zip: formData.zip,
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="pds-max-h-screen pds-overflow-auto pds-px-5 pds-pb-5 pds-pt-5.5">
        <DialogClose />

        <DialogTitle className="pds-mb-4 pds-text-title-md">Recommended address</DialogTitle>

        <form className="pds-mb-4.5">
          <fieldset>
            <legend className="pds-mb-0">
              <DialogDescription className="pds-mb-4 pds-text-surface-textSubdued">
                We found a matching mailing address. Please select one address.
              </DialogDescription>
            </legend>

            {showError && (
              <Alert variant="error" className="pds-mb-4">
                <div className="flex-row pds-flex pds-gap-3">
                  <Icon path={mdiAlertCircleOutline} size={1} aria-hidden />
                  <div>Please select an address to continue</div>
                </div>
              </Alert>
            )}

            <div className="pds-flex pds-flex-col pds-gap-4">
              <AddressRadioCard
                id="enteredAddress"
                title="You entered:"
                address={enteredAddress}
                onClick={clickedAddress => {
                  setConfirmedAddress(clickedAddress);
                  setShowError(false);
                }}
              />

              <AddressRadioCard
                id="recommendedAddress"
                title="Recommended:"
                address={validatedAddress}
                onClick={clickedAddress => {
                  setConfirmedAddress(clickedAddress);
                  setShowError(false);
                }}
              />
            </div>
          </fieldset>
        </form>

        <div className="pds-flex pds-flex-col pds-justify-center pds-gap-4">
          <Button
            width="full"
            buttonStyle="solid"
            buttonColor="neutral"
            onClick={() => {
              if (!confirmedAddress) {
                setShowError(true);
                return;
              }

              onClickSelect(confirmedAddress);
            }}
          >
            Select Address
          </Button>

          <Button width="full" buttonStyle="outlined" buttonColor="neutral" onClick={onClickBack}>
            Back
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

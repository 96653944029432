import React, { PropsWithChildren, useEffect, useState } from 'react';

export interface AppStoreBannerProps {
  dismissDuration: number;
  children: (handleDismiss: () => void, key: string) => React.ReactNode;
}

const APP_STORAGE_KEY = 'appStoreBannerDismissed';

const AppStoreBanner = ({ dismissDuration, children }: PropsWithChildren<AppStoreBannerProps>) => {
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const dismissed = localStorage.getItem(APP_STORAGE_KEY);
    if (!dismissed) return;

    const dismissTime = parseInt(dismissed, 10);
    const currentTime = Date.now();

    if (currentTime - dismissTime < dismissDuration) {
      setIsDismissed(true);
    }
  }, [dismissDuration]);

  const handleDismiss = () => {
    localStorage.setItem(APP_STORAGE_KEY, String(Date.now()));
    setIsDismissed(true);
  };

  if (isDismissed) return null;

  return <>{children(handleDismiss, APP_STORAGE_KEY)}</>;
};

export default AppStoreBanner;

import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import { Container } from 'react-bootstrap';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CmsButtonLink } from 'src/components/button/CmsButton';
import { CMS_THEME } from '../common/enums';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

interface StorageRichTextProps {
  node: IStorageRichText;
}

const DefaultSettings = {
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_variant: {
    text_align: 'center',
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StorageRichText: React.FC<StorageRichTextProps> = ({ node: richText }) => {
  const settings = Object.assign(DefaultSettings, richText?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings?.entity_buttons?.primary_button;
  const textAlign = settings.entity_variant.text_align;

  return (
    <Wrapper
      id={richText.component_id}
      data-id={richText.drupal_id}
      className="pds-px-0 pds-py-4  lg:pds-py-5.5"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container
        className={cx(
          `pds-flex pds-flex-col pds-text-${textAlign}`,
          textAlign === 'center' && 'align-items-center',
        )}
      >
        {richText?.body?.processed ? (
          <RichText cmsTheme={cmsTheme} body={richText.body.processed} />
        ) : null}
        {richText?.links?.length && richText.links.length > 0 ? (
          <ButtonDiadWrapper className="pds-mt-4">
            {richText.links.map((linkObject, index) => {
              function handleGA() {
                sendIt({
                  event: 'module_interaction',
                  eventParams: {
                    component_id: linkObject.title ?? '[not set]',
                    component_type: 'rich_text',
                    content_name: index === 0 ? 'primary_cta' : 'secondary_cta',
                  },
                });
              }

              return linkObject ? (
                <CmsButtonLink
                  key={linkObject.url}
                  variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                  onClick={handleGA}
                  analytics={false}
                  to={linkObject.url}
                  cmsTheme={cmsTheme}
                  {...linkObject?.options?.attributes}
                  className="pds-inline-block pds-whitespace-nowrap pds-text-body-md pds-font-bold"
                >
                  {linkObject.title}
                </CmsButtonLink>
              ) : null;
            })}
          </ButtonDiadWrapper>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default StorageRichText;

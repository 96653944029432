import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import * as React from 'react';
import { IGeoMenu } from 'src/utils/helpers';
import { remCalc } from '../../theme/theme';
import MegaMenu from './MegaMenu';

const StyledMegaMenu = styled(MegaMenu)`
  .nav-link {
    font-size: ${() => remCalc(22)};
  }
`;

interface ParagraphProps extends Paragraph {
  relationships: {
    menu: IStorageMegaMenu | IStorageMenu;
  };
}

interface ParagraphNavigationProps {
  node: ParagraphProps;
  props?: {
    showMenu: boolean;
    translations?: Translations;
    linkgroup: IGeoMenu;
  };
}

const ParagraphNavigation: React.FC<ParagraphNavigationProps> = function ({ node, props }) {
  const { menu } = node.relationships;
  const classes = node.behavior_settings?.decoupled_styles?.classes?.join(' ') || '';
  const offset = 95 + 48;
  if (menu.type === 'storage__mega_menu') {
    return (
      <StyledMegaMenu
        node={menu}
        offset={offset}
        className={cx(classes, props?.showMenu && 'show')}
        translations={props?.translations}
        linkgroup={props?.linkgroup}
      />
    );
  }
  return null;
};

export default ParagraphNavigation;

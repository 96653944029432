import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'src/components/Link';

const RecaptchaDisclaimer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="pds-text-center pds-text-surface-textSubdued">
      {t('This site is protected by reCAPTCHA and the Google')}{' '}
      <Link to="https://policies.google.com/privacy">{t('Privacy Policy')}</Link> {t('and')}{' '}
      <Link to="https://policies.google.com/terms">{t('Terms of Service')}</Link> {t('apply')}.
    </div>
  );
};
export default RecaptchaDisclaimer;

import { Button } from '@purinanbm/pds-ui';
import React from 'react';
import Link from 'src/components/Link';
import {
  RewardItemOrderSummary,
  RewardItemSummaryProduct,
  RewardItemSummaryWrapper,
} from 'src/components/purina_perks/reward_catalog/RewardCatalogItemSummary';
import { Reward } from 'src/components/purina_perks/reward_catalog/RewardCatalogList';

const testItem = {
  id: 3033,
  pid: 'ZELOROLLER00018',
  points: 0,
  name: 'Dog House- Free voucher',
  description: 'Dog House- Free voucher',
  endTime: '2025-01-01 04:24:44',
  expired: false,
  imageUrl: 'https://incrm-solutions.s3.amazonaws.com/rewards/2a89ae27-6fd7-4d05-b903-9b544bf.jpeg',
  thumbnailUrl:
    'https://incrm-solutions.s3.amazonaws.com/rewards/24d290e4-668e-476e-b28b-a606a4d.jpeg',
  type: 'PHYSICAL' as Reward['type'],
};

const testItem2 = {
  id: 122320,
  pid: '5CATFDCOUPON34',
  points: 750,
  name: '$5 Purina Dry Cat Food Coupon',
  description:
    '<p>SAVE $5.00 on one (1) 5 lb or larger Beyond®, Cat Chow®, Fancy Feast®, Friskies®, Kit & Kaboodle®, Kitten Chow®, Pro Plan® or Purina ONE® Dry Cat Food</p>   &bull;  Limit one coupon per specified item(s) purchased<br /> &bull;  Coupon expiration date 3/31/25<br /> &bull;  Paper coupon will arrive in the mail<br />',
  endTime: '2024-12-31 15:42:15',
  expired: false,
  imageUrl: 'https://incrm-solutions.s3.amazonaws.com/rewards/41853918-b8a3-4d1c-9a0c-97c62b8b.jpg',
  thumbnailUrl:
    'https://incrm-solutions.s3.amazonaws.com/rewards/a4de8059-d24c-4a95-95e6-590a717f.jpg',
  type: 'PHYSICAL' as Reward['type'],
};

const DummyPage = () => {
  return (
    <div className="pds-flex pds-h-dvh pds-w-full pds-flex-col pds-items-center pds-justify-center">
      <div className="pds-max-w-[500px]">
        <RewardItemSummaryWrapper title="Your Item">
          <RewardItemSummaryProduct item={testItem} />
          <RewardItemOrderSummary item={testItem} />
          <Button
            as={Link}
            state={{ reward: testItem, showRewardCheckoutSuccess: true }}
            className="pds-mt-4"
            buttonColor="neutral"
            type="submit"
            width="full"
          >
            Place Order
          </Button>
        </RewardItemSummaryWrapper>
      </div>
      <div className="pds-mt-4 pds-max-w-[500px]">
        <RewardItemSummaryWrapper title="Your Item">
          <RewardItemSummaryProduct item={testItem2} />
          <RewardItemOrderSummary item={testItem2} />
          <Button
            as={Link}
            state={{ reward: testItem2, showRewardCheckoutSuccess: true }}
            to="/purina-perks/reward-checkout/success"
            className="pds-mt-4"
            buttonColor="neutral"
            type="submit"
            width="full"
          >
            Place Order
          </Button>
        </RewardItemSummaryWrapper>
      </div>
    </div>
  );
};

export default DummyPage;

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  InfoBox,
  Spinner,
} from '@purinanbm/pds-ui';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useImperativeHandle, useRef } from 'react';
import { mdiShoppingCartOutline } from 'src/assets/icons/mdiIcons';
import { Reward } from '../../../services/loyalty/types';
import {
  RewardItemOrderSummary,
  RewardItemSummaryProduct,
  RewardItemSummaryWrapper,
} from '../reward_catalog/RewardCatalogItemSummary';
import { RewardDivider } from './RewardDivider';

const SubmissionError: React.FC<{ error: Error | undefined }> = ({ error }) => {
  if (!error) return null;

  const message = () => {
    if (error.message?.toLowerCase()?.includes('allowed limit is 1')) {
      return 'Sorry. You’ve already redeemed your free sample.';
    }

    return 'Something went wrong. Please try again.';
  };

  return (
    <InfoBox role="alert" variant="error">
      <InfoBox.Icon />
      {message()}
    </InfoBox>
  );
};

const SubmitButton = React.forwardRef<React.ElementRef<'button'>, { submissionIsLoading: boolean }>(
  ({ submissionIsLoading }, ref) => {
    return (
      <Button
        ref={ref}
        disabled={submissionIsLoading}
        className="pds-mt-4 pds-flex pds-justify-center"
        buttonColor="neutral"
        type="submit"
        width="full"
      >
        Place Order
        {submissionIsLoading && <Spinner className="pds-ml-3" color="currentColor" />}
      </Button>
    );
  },
);

const RewardCheckoutSummary = React.forwardRef<
  {
    focusSubmitButton: () => void;
  },
  {
    submissionIsLoading: boolean;
    item: Reward;
    error: Error | undefined;
  }
>(({ item, error, submissionIsLoading }, ref) => {
  const desktopButtonRef = useRef<HTMLButtonElement>(null);
  const mobileButtonRef = useRef<HTMLButtonElement>(null);

  const breakpoint = useBreakpoint();

  useImperativeHandle(
    ref,
    () => ({
      focusSubmitButton: () => {
        const element = breakpoint.lg ? desktopButtonRef.current : mobileButtonRef.current;

        if (!element) {
          console.warn('Button is not defined');
          return;
        }

        element.focus();
      },
    }),
    [breakpoint],
  );

  return (
    <>
      <div className="pds-sticky pds-bottom-0 pds-col-span-4 pds-mt-4 pds-block pds-rounded pds-bg-surface pds-shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] lg:pds-hidden zoom-400:pds-relative">
        <Accordion options={{ headingLevel: 'h2' }} type="single" collapsible>
          <AccordionItem className="pds-border-b-0 pds-p-0" value="1">
            <AccordionTrigger className="pds-p-4 pds-text-body-md pds-font-semibold">
              Order Summary
            </AccordionTrigger>
            <AccordionContent>
              <div className="pds-px-4">
                <RewardDivider headingLevel="h3" title="Your Item" icon={mdiShoppingCartOutline} />
                <RewardItemSummaryProduct item={item} />
                <RewardItemOrderSummary headingLevel="h3" item={item} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <SubmissionError error={error} />
        <div className="pds-px-4 pds-pb-4">
          <SubmitButton ref={mobileButtonRef} submissionIsLoading={submissionIsLoading} />
        </div>
      </div>
      <div className="pds-sticky pds-bottom-0 pds-col-span-4 pds-hidden lg:pds-block">
        <RewardItemSummaryWrapper title="Your Item">
          <RewardItemSummaryProduct item={item} />
          <RewardItemOrderSummary item={item} />
          <SubmissionError error={error} />
          <SubmitButton ref={desktopButtonRef} submissionIsLoading={submissionIsLoading} />
        </RewardItemSummaryWrapper>
      </div>
    </>
  );
});

export default RewardCheckoutSummary;

import { SidebarMenu } from '@purinanbm/pds-ui';
import React, { FC, PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const navOptions = [
  {
    title: 'Receipt',
    url: '#',
    subtitle: 'Upload receipts and submit to earn points.',
    submenu: [
      {
        title: 'Receipt Home',
        url: '3533-receipt-home',
        subtitle: 'Upload receipts and submit to earn points.',
      },
      {
        title: 'Upload a Receipt',
        url: '#',
      },
      {
        title: 'Submission History',
        url: '#',
      },
    ],
  },
  {
    title: 'Catalog',
    url: '#',
    subtitle: 'Browse the catalog to redeem points for tail-wagging rewards.',
    submenu: [
      {
        title: 'Catalog Home',
        url: '#',
      },
      {
        title: 'Rewards Catalog',
        url: '#',
      },
    ],
  },
  {
    title: 'Write a Review',
    url: '#',
    subtitle: 'Information about Write a Review goes here',
    submenu: [
      {
        title: 'Reviews',
        url: '#',
      },
    ],
  },
];

const PerksLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container fluid className="pds-w-full pds-p-5 md:!pds-w-4/5">
      <Row>
        <Col xs={12} md={3}>
          <SidebarMenu
            title="Purina Perks"
            navigationOptions={navOptions}
            optionComponent="button"
            titleProps={{
              className: 'pds-text-title-sm-future',
            }}
            optionProps={{
              className: 'pds-border-none',
            }}
          />
        </Col>
        <Col
          md={9}
          className="!pds-mt-4 pds-h-[700px] pds-rounded-xl pds-border pds-border-solid pds-border-primary !pds-pl-0 !pds-pr-0 md:pds-mt-0 md:pds-flex"
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default PerksLayout;

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import { CMS_OVERLAY, CMS_THEME } from 'src/common/enums';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CmsButtonLink } from 'src/components/button/CmsButton';
import { HeroDefaultSettings } from '../types';

interface HeroButtonsProps {
  heroCard: IStorageHeroCard;
  hideQrCode?: boolean;
  isQrHero?: boolean;
}

const HeroButtons: React.FC<HeroButtonsProps> = ({ heroCard, hideQrCode, isQrHero }) => {
  const settings = Object.assign(HeroDefaultSettings, heroCard?.behaviors || {});
  const { element: HeadingElement } = settings.entity_heading;
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  const cmsTheme = heroCard.behaviors?.entity_variant?.theme || CMS_THEME.LIGHT;
  const cmsOverlayTheme = heroCard.behaviors?.entity_variant?.overlay || CMS_OVERLAY.LIGHT;
  const variant = settings.entity_variant.hero_variant_type;
  const breakpoint = useBreakpoint();

  // Hides buttons when a qr hero not on a mobile device.
  if (isQrHero && !hideQrCode) return null;

  return (
    <ButtonDiadWrapper>
      {heroCard.links.map((linkObject, index) => {
        const hasSecondaryButton = heroCard.links.length > 1;
        const handleGA = () => {
          sendIt({
            event: 'module_interaction',
            eventParams: {
              component_id: hasSecondaryButton
                ? `primary_cta: ${heroCard.links[0].title}, secondary_cta: ${heroCard.links[1].title}`
                : `primary_cta: ${heroCard.links[0].title}`,
              component_type: 'hero',
              content_name: `${HeadingElement} / ${heroCard.title}`,
            },
          });
        };
        return (
          <CmsButtonLink
            key={linkObject.url}
            variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
            onClick={handleGA}
            analytics={false}
            to={linkObject.url}
            cmsTheme={
              breakpoint.lg && variant === 'background_hero'
                ? (cmsOverlayTheme as unknown as CMS_THEME)
                : cmsTheme
            }
            {...linkObject?.options?.attributes}
          >
            {linkObject.title}
          </CmsButtonLink>
        );
      })}
    </ButtonDiadWrapper>
  );
};

export default HeroButtons;

import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

export const NavigationBlockData = () => {
  const data = useStaticQuery(graphql`
    {
      allBlockContentNavigationBlock {
        edges {
          node {
            field_visibility
            relationships {
              field_component {
                type: __typename
                ...StorageSmartAppBanner
              }
            }
            langcode
          }
        }
      }
    }
  `);

  return useMemo(() => data.allBlockContentNavigationBlock.edges, [data]);
};

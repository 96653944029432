import { useLocation } from '@reach/router';
import React from 'react';
import { ImpersonateStorage } from '../../components/impersonateStorage/ImpersonateStorage';
import StorageHeroCard from './StorageHeroCard';

const schema = {
  field_category: [['qr'], ['']],
  hero_variant_type: ['standard', 'background_hero_content_card', 'background_hero', 'short'],
  content_position: ['left', 'right'],
  theme: ['dark', 'light'],
  overlay: ['dark', 'light'],
  foreground_image_alignment: ['left', 'right'],
  primary_button: ['btn', 'btn-outline'],
};

const StorageHeroCardHelper = (props: { node: IStorageHeroCard }) => {
  const location = useLocation();
  const isTestHero = location.pathname === '/hero-testing';

  if (isTestHero) {
    return (
      <>
        <ImpersonateStorage component={StorageHeroCard} node={props.node} schema={schema} />{' '}
      </>
    );
  }
  return <StorageHeroCard {...props} />;
};

export default StorageHeroCardHelper;

import { minimatch } from 'minimatch';
import React from 'react';
import { getCmsComponent } from 'src/utils/cmsComponentHelpers';

export type BasicBlocksProps = {
  location?: string;
  data: any;
  language?: string;
};

type QueryProps = {
  node: {
    relationships: {
      field_component: any;
    };
  };
};

function matchAnyPattern(location: string, patternsStr: string): boolean {
  const patterns: string[] = patternsStr.split('\r\n');
  const excludePatterns = patterns.filter(p => p.startsWith('!')).map(p => p.replace('!', ''));
  const includePatterns = patterns.filter(p => !p.includes('!'));
  // /index should be replaced with / to match glob pattern / for root/homepage URLS
  const replacedLocation = location.replace('/index', '/');

  if (includePatterns.length === 0) includePatterns.push('/**/*');

  const match = includePatterns.some(p => minimatch(replacedLocation, p)) || false;
  const negateMatch = excludePatterns.some(p => minimatch(replacedLocation, p)) || false;

  return match && !negateMatch;
}

const BasicBlocks = ({ location = '', data, language = 'en' }: BasicBlocksProps) => {
  const activeBlocks: [] = data.filter(({ node }: any) =>
    node.langcode === language && node.field_visibility === null
      ? true
      : matchAnyPattern(location, node.field_visibility),
  );

  if (activeBlocks.length === 0) return null;

  return (
    <div>
      {activeBlocks.map((block: QueryProps, index: number) => {
        const component = block.node.relationships.field_component;
        if (!component) return null;
        return (
          <React.Fragment key={component.id}>
            {getCmsComponent({ node: component, index })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BasicBlocks;

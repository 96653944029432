import { Heading } from '@purinanbm/pds-ui';
import React from 'react';

const t = ['h1', 'h2', 'h3', 'h4', 'h5'];
const p = ['dislayMedium', 'dislaySmall', 'titleLarge', 'titleMedium', 'titleSmall'];
const tb = ['span', 'span', 'span', 'span'];
const pb = ['bodyXSmall', 'bodySmall', 'bodyMedium', 'bodyLarge'];

const DummyPage: React.FC = () => {
  return (
    <>
      <div>HEADINGS</div>
      <div className="pds-grid pds-grid-cols-2 pds-gap-4 [&>*]:pds-flex [&>*]:pds-items-center [&>*]:pds-border">
        <div>SITE</div>
        <div>[P]</div>
        {t.map((x, i) => (
          <>
            <div className={`typography_${x}`}>Heading typography_{i + 1}</div>
            <Heading as="div" size={p[i]}>
              Heading {p[i]}
            </Heading>
          </>
        ))}
      </div>
      <div className="pds-mt-6">BODY</div>
      <div className="pds-grid pds-grid-cols-2">
        <div>SITE</div>
        <div>[P]</div>
        {tb.map((a, i) => (
          <>
            <div className={`typography_${tb}`}>
              Body size line example for this font size p-tag
            </div>
            <Heading as={a} size={pb[i]}>
              Body size line example for this font size {pb[i]}
            </Heading>
          </>
        ))}
      </div>
    </>
  );
};

export default DummyPage;

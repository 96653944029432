import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { StorageHeroCardStyles } from '../StorageHeroCardStyles';
import { HeroDefaultSettings, IHeroStyleSettings } from '../types';

interface HeroBgSectionProps {
  heroCard: IStorageHeroCard;
  heroImage: IGatsbyImageData;
  lazyLoad: 'eager' | 'lazy';
  foregroundImage: IGatsbyImageData;
  styleSettings: IHeroStyleSettings;
  hideQrCode?: boolean;
}

const HeroBgSection: React.FC<HeroBgSectionProps> = ({
  heroCard,
  heroImage,
  lazyLoad,
  foregroundImage,
  hideQrCode,
  styleSettings,
}) => {
  const { bgImg, foregroundImg, bgWrapper } = StorageHeroCardStyles(styleSettings);
  const settings = Object.assign(HeroDefaultSettings, heroCard?.behaviors || {});
  const variant = settings.entity_variant.hero_variant_type;

  return (
    <div className="hero-media pds-relative">
      {heroCard?.relationships?.image && (
        <div className={bgWrapper()}>
          <GatsbyImage
            alt={heroCard.relationships.image.field_media_image.alt}
            image={heroImage}
            className={bgImg()}
            objectFit="cover"
            objectPosition={getFocalPoint(heroCard.relationships.image)}
            loading={lazyLoad}
          />
          {/* This is for 50/50 layouts to force the covered image onto one side or the other */}
          {(variant === 'short' || variant === 'standard') && (
            <div className="pds-hidden pds-w-full lg:pds-block" />
          )}
        </div>
      )}
      {/* Mobile foreground image */}
      {foregroundImage && !hideQrCode && (
        <div className="pds-absolute pds-left-0 pds-top-0 pds-flex pds-h-full pds-w-full pds-items-center pds-justify-center lg:!pds-hidden">
          <GatsbyImage
            alt={heroCard.relationships.secondary_image.field_media_image.alt}
            image={foregroundImage}
            className={foregroundImg()}
            objectFit="contain"
            imgClassName="pds-object-contain"
            loading={lazyLoad}
          />
        </div>
      )}
    </div>
  );
};

export default HeroBgSection;

import React from 'react';
import RichText from 'src/components/rich_text/RichText';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Layout from 'src/components/Layout';

const H20Prototypes: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage(filter: { path: { regex: "/H20//" } }) {
        edges {
          node {
            path
          }
        }
      }
    }
  `);
  return (
    <Layout language="en">
      <RichText className="pds-mx-auto pds-max-w-[600px]">
        <h1 className="pds-text-display-sm">H20 Prototypes</h1>
        <p>
          The "H20" directory is used for developing and testing frontend work that is not yet ready
          for integration into production but needs to be publicly tested. It can also be used for
          demonstrating the outcomes of experimental features.
        </p>
        <p>
          Developers should name each page within the "H20" directory based on the associated Jira
          ticket number. For example, a Jira ticket titled H20-12345 should create a file called
          12345.tsx.
        </p>
        <p>An example final path for this page would be /H20/12345.</p>
        <p>
          For convenience, engineers should include links to this path in the Jira ticket, and the
          link to the Jira ticket in the demo page.
        </p>
        <p>
          Note: These pages are deleted during Netlify Production builds. Please refer to the
          onCreatePage function in gatsby-node.js for more details.
        </p>
        <p>
          Once the follow-up integration work is completed, these demo pages should be removed from
          the repository.
        </p>
        <h2 className="pds-text-title-lg">Active Work</h2>
        <p>The following are ongoing works in progress:</p>
        <ol>
          {data.allSitePage.edges.map(page => (
            <li key={page.node.path}>
              Demo: <Link to={page.node.path}>H20-{page.node.path.split('/')[2]}</Link>
              <br />
              Jira:{' '}
              <Link
                to={`https://purina-nbm.atlassian.net/browse/H20-${page.node.path.split('/')[2]}`}
                target="_blank"
              >
                H20-{page.node.path.split('/')[2]}
              </Link>
            </li>
          ))}
        </ol>
      </RichText>
    </Layout>
  );
};

export default H20Prototypes;

import Icon from '@mdi/react';
import { Button } from '@purinanbm/pds-ui';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mdiTrashCanOutline } from 'src/assets/icons/mdiIcons';
import InputField from '../new_components/InputField';
import { ProfileFormTypes } from '../utils/profile';

const ControlledPets = ({
  petType,
  fields,
  remove,
}: {
  petType: 'cat' | 'dog';
  fields: FieldArrayWithId<ProfileFormTypes, 'catPets' | 'dogPets'>[];
  remove: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const { register } = useFormContext<ProfileFormTypes>();

  return (
    <>
      {fields.map((pet, index: number) => {
        const petName = `${petType}Pets` as const;
        const petNameId = `${petName}.${index}.name` as const;
        const petBirthDateId = `${petName}.${index}.birthDate` as const;

        /* i18next-extract-mark-context-next-line ["cat", "dog"] */
        const nameLabel = t('petType', {
          context: petType,
        });

        const handleRemovePet = () => {
          remove(index);
        };
        return (
          <fieldset key={pet.id} className="pds-rounded pds-bg-surface-layer pds-p-4">
            <legend className="pds-text-title-sm">
              {nameLabel} #{index + 1}
            </legend>
            <Row key={index} className="pds-py-3 pds-gx-1 pds-gy-3 sm-bt:pds-gx-2">
              <Col xs={12} lg={6}>
                <input
                  type="hidden"
                  value={petType.toUpperCase()}
                  {...register(`${petName}.${index}.petType.keyName` as const)}
                />
                <InputField
                  name={petNameId}
                  label={t('Name')}
                  aria-label={`${nameLabel} #${index + 1} ${t('Name')}`}
                  required
                  hideAsterisk={false}
                  // i18next-extract-mark-context-next-line ["cat", "dog"]
                  placeholder={t('petTypeName', { context: petType })}
                />
              </Col>
              <div className="pds-flex lg-bt:!pds-w-1/2 lg-bt:!pds-flex-[0_0_auto] [@media(max-width:365px)]:pds-flex-wrap">
                <div className="[@media(min-width:365px)]:pds-flex-1">
                  <InputField
                    name={petBirthDateId}
                    label={t('Birthdate')}
                    aria-label={`${nameLabel} #${index + 1} ${t('Birthdate')}`}
                    hideAsterisk={false}
                    required
                    type="date"
                    className="pds-min-w-min"
                    placeholder="mm / dd / yyyy"
                  />
                </div>
                <div className="pds-flex-shrink-0 [@media(min-width:365px)]:pds-pl-[12px]">
                  <Button
                    key={`${pet.id}-button`}
                    type="button"
                    aria-label={`${nameLabel} #${index + 1} ${t('Remove')}`}
                    buttonStyle="tonal"
                    buttonColor="neutral"
                    className="pds-mt-4.5 pds-h-[56px] pds-bg-gray-950/[0.05]"
                    onClick={handleRemovePet}
                  >
                    <Icon className="pds-stroke-2" path={mdiTrashCanOutline} size={1} />
                  </Button>
                </div>
              </div>
            </Row>
          </fieldset>
        );
      })}
    </>
  );
};

export default ControlledPets;

import { css } from '@linaria/core';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import Link from '../Link';

interface ParagraphProps extends Paragraph {
  link: LinkProps;
  relationships: {
    media?: {
      attributes: {
        alt?: string;
      };
      relationships: {
        logo: {
          url: string;
        };
      };
    };
  };
}

interface ParagraphLinkedLogoProps {
  node: ParagraphProps;
}

// create new class to logo using cx.
const logoClass = css`
  .search-visible & {
    display: none;
  }
`;

const ParagraphLinkedLogo: React.FC<ParagraphLinkedLogoProps> = function ({ node }) {
  const { link } = node;
  const media = node.relationships?.media;
  const logo = media?.relationships.logo?.url;
  const alt = media?.attributes?.alt || '';

  if (logo) {
    return (
      <AnalyticsPoint
        type="module"
        typeLabel="site_navigation"
        label="site navigation"
        as="div"
        className={`${logoClass} pds-mr-auto [&_img]:pds-h-[2.8125rem] [&_img]:pds-max-w-full`}
      >
        <AnalyticsPoint
          type="component"
          typeLabel="home_logo"
          label="Home logo"
          eventLabel="Home logo"
          action="logo click"
        >
          <Link to={link.url} {...link?.options?.attributes} aria-current={undefined}>
            <img src={logo} alt={alt} className="logo" />
          </Link>
        </AnalyticsPoint>
      </AnalyticsPoint>
    );
  }

  return null;
};

export default ParagraphLinkedLogo;

import { IInput, IMaskInput, Input, InputWithMask } from '@purinanbm/pds-ui';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { FieldErrorDisplay } from './FieldError';

type Props = Omit<IInput, 'name'> & {
  required?: boolean;
  name: string;
  control?: Control<any>;
  // !TODO: Add Tooltip tooltip?: React.ReactElement;
  maxLength?: number;
  autoComplete?: string;
  hideAsterisk?: boolean;
};
type MaskProps = Omit<IMaskInput, 'name'> & {
  required?: boolean;
  name: string;
  control?: Control<any>;
  hideAsterisk?: boolean;
  // !TODO: Add Tooltip tooltip?: React.ReactElement;
  maxLength?: number;
  autoComplete?: string;
};

export const InputFieldWithMask = ({
  required = false,
  hideAsterisk = true,
  control,
  label,
  name,
  ...rest
}: MaskProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className="pds-flex pds-flex-col">
      <InputWithMask
        label={label}
        id={name}
        labelProps={{
          className: !hideAsterisk
            ? 'has-[~div>input[aria-required=true]]:after:pds-content-required'
            : '',
        }}
        className="pds-bg-surface pds-shadow-surface-text placeholder:pds-text-surface-textSubdued"
        aria-invalid={!!error}
        data-error={!!error}
        aria-required={required}
        {...field}
        type="text"
        aria-describedby={`${name}-message`}
        {...rest}
      />
      {error && <FieldErrorDisplay error={error} id={`${name}-message`} />}
    </div>
  );
};

const InputField = ({
  hideAsterisk = true,
  required = false,
  control,
  label,
  name,
  ...rest
}: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className="pds-flex pds-flex-col">
      <Input
        label={label}
        id={name}
        labelProps={{
          className: !hideAsterisk
            ? 'has-[~div>input[aria-required=true]]:after:pds-content-required'
            : '',
        }}
        className="pds-bg-surface pds-shadow-surface-text placeholder:pds-text-surface-textSubdued"
        aria-invalid={!!error}
        data-error={!!error}
        aria-required={required}
        {...field}
        type="text"
        aria-describedby={`${name}-message`}
        {...rest}
      />
      {error && <FieldErrorDisplay error={error} id={`${name}-message`} />}
    </div>
  );
};

export default InputField;

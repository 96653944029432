import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import ProfileForm from 'src/components/forms/ProfileForm';
import RichText from 'src/components/rich_text/RichText';

interface StorageAnsiraProfileFormProps {
  node: IStorageAnsiraProfileForm;
}

const StorageAnsiraProfileForm: React.FC<StorageAnsiraProfileFormProps> = function ({
  node: ansiraProfileForm,
}) {
  const { t } = useTranslation();
  const title = ansiraProfileForm.title || '';
  const body = ansiraProfileForm.body?.processed || '';
  return (
    <Container
      id={ansiraProfileForm.component_id}
      data-id={ansiraProfileForm.drupal_id}
      className="pds-py-5"
    >
      <Row className="md-bt:pds-justify-center">
        <Col md={8}>
          <h2 className="pds-text-center pds-text-title-lg">{title}</h2>
          {body && (
            <RichText className="pds-mx-auto pds-mb-4 pds-max-w-md pds-text-center" body={body} />
          )}
          <div className="pds-mb-4">
            <span className="text-danger">*{t('Required Field')}</span>
          </div>
          <ProfileForm
            indexOfModule={ansiraProfileForm.indexOnPage}
            sourceCode={ansiraProfileForm.source_code}
            settings={ansiraProfileForm.settings}
            language={ansiraProfileForm.langcode}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageAnsiraProfileForm;

import Icon from '@mdi/react';
import { Input } from '@purinanbm/pds-ui';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { mdiMagnify } from 'src/assets/icons/mdiIcons';
import { ParagraphSearch } from 'src/components/paragraphs/ParagraphSearchForm';
import SearchPageService from 'src/components/search_listing/SearchPageService';

interface SearchFormProps {
  node?: ParagraphSearch;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBarForm: React.FC<SearchFormProps> = function ({ node, setShowSearch }) {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const [keywords, setKeywords] = React.useState('');
  const location = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));
  const searchPages = SearchPageService.useGetSearchPages(activeLanguage);
  const activePage = SearchPageService.useGetActiveSearchPage(activeLanguage).api_slug;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  const onSearchFocus = () => {
    const newPath = searchPages.filter(page => {
      return page.node.api_slug === activePage;
    })[0].node.path.alias;
    const analyticsData = {
      event: 'search_initiate',
      eventCategory: 'search_initiate',
      eventAction: newPath,
      eventLabel: node?.type ?? '',
      eventParams: {
        default_search_category: newPath,
        page_type: node?.type ?? '',
      },
    };
    sendIt(analyticsData);
  };

  const setFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length);
  };

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = keywords !== '' ? `?keywords=${keywords}` : '';
    if (!location || !params) return;

    const newPage = searchPages.filter(page => {
      return page.node.api_slug === activePage;
    })[0].node;

    const newPath = newPage.path.alias;
    navigate(`${newPath}${params}`, { state: { didSearch: true } });
  };

  React.useEffect(() => {
    setKeywords(queryParams.keywords ?? '');
  }, [queryParams.keywords, setKeywords]);

  React.useEffect(() => {
    const closeMenu: React.EventHandler<any> = (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowSearch(false);
      }
    };
    window.addEventListener('keydown', closeMenu);
    return () => window.removeEventListener('keydown', closeMenu);
  }, [setShowSearch]);

  return (
    <form onSubmit={handleSubmit} className="pds-max-w-[676px]">
      <Input
        autoFocus
        aria-label={t('Search site')}
        hasAdornmentEnd
        adornmentEnd={
          <button
            type="submit"
            aria-label={t('Site search submit')}
            className="pds-text-surface-text"
          >
            <Icon path={mdiMagnify} size={1} aria-hidden />
          </button>
        }
        value={keywords}
        name="keywords"
        onChange={handleOnChange}
        onFocus={onSearchFocus}
        type="text"
        onClick={setFocus}
        className="pds-h-[48px]"
      />
    </form>
  );
};

export default SearchBarForm;

import Icon from '@mdi/react';
import { ButtonVariantProps, buttonStyles, pdsTv } from '@purinanbm/pds-ui';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import * as React from 'react';
import { mdiOpenInNew } from 'src/assets/icons/mdiIcons';
import { prepareAttribute } from '../utils/helpers';

export const linkStyles = pdsTv({
  base: 'pds-font-medium pds-text-surface-text pds-underline pds-transition-colors hover:pds-text-surface-text focus:pds-text-surface-text [&_>_svg]:pds-ml-[5px]',
  variants: {
    theme: {
      dark: 'pds-text-white hover:pds-text-white focus:pds-text-white',
      light: '',
      neutral: '',
    },
  },
  defaultVariants: {
    theme: 'light',
  },
});

interface AttributeProps {
  [key: string]: any;
}

interface LinkPropsExtended {
  analytics?: boolean;
  /** Hides the icon for external links that open new tabs */
  hideExternalIcon?: boolean;
  theme?: 'light' | 'dark' | 'neutral';
}

export type LinkProps = Omit<GatsbyLinkProps<{}>, 'ref'> & LinkPropsExtended;

const Link = React.forwardRef<React.ElementRef<'a'>, LinkProps>(
  ({ children, analytics = true, theme = 'light', to, ...props }, ref) => {
    const styles = React.useMemo(
      () =>
        linkStyles({
          theme,
          className: [props?.className, analytics && 'js-track'],
        }),
      [props?.className, theme, analytics],
    );

    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to);
    const file = /\.[0-9a-z]+(?=\?|$)/i.test(to);

    const attributes: AttributeProps = {};
    // Rewrite attributes to valid html.
    Object.entries(props).forEach(attribute => {
      const key: string = prepareAttribute(attribute[0]);
      attributes[key] = attribute[1];
    });

    const withIcon = attributes?.icon;

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      const handlePath = () => {
        if (!props.lang || !to.includes('/index')) return to;

        // If we're on the home page
        return props.lang === 'en' ? '/' : `/${props.lang}`;
      };
      if (file) {
        return (
          <a ref={ref} href={handlePath()} {...attributes} className={styles}>
            {children}
          </a>
        );
      }

      if (props?.target !== '_blank') {
        return (
          <GatsbyLink ref={ref} to={handlePath()} {...attributes} className={styles}>
            {children}
          </GatsbyLink>
        );
      }
    }

    return (
      <a ref={ref} href={to} {...attributes} className={styles}>
        {children}
        {props?.target === '_blank' && !props.hideExternalIcon && !withIcon && (
          <Icon title="Open in new window" path={mdiOpenInNew} size={0.6} />
        )}
      </a>
    );
  },
);

export const ButtonLink = React.forwardRef<React.ElementRef<'a'>, LinkProps & ButtonVariantProps>(
  ({ to, buttonColor, buttonStyle, size, width, className, children, ...props }, ref) => {
    const styles = React.useMemo(
      () =>
        buttonStyles({
          buttonColor,
          buttonStyle,
          size,
          width,
          className: ['pds-no-underline', className],
        }),
      [buttonColor, buttonStyle, size, width, className],
    );

    return (
      <Link ref={ref} to={to} className={styles} {...props}>
        {children}
      </Link>
    );
  },
);

export default Link;

import { Button, buttonStyles } from '@purinanbm/pds-ui';
import React from 'react';

import { CMS_THEME } from 'src/common/enums';
import Link from '../Link';

type CommonCmsButtonProps = {
  cmsTheme: CMS_THEME;
  width?: 'auto' | 'full';
  variant?: 'btn' | 'btn-outline' | 'btn-link';
};

type CmsButtonProps = CommonCmsButtonProps & React.ComponentProps<typeof Button>;
type CmsButtonLinkProps = CommonCmsButtonProps & React.ComponentProps<typeof Link>;

const cmsThemeToColor = {
  [CMS_THEME.LIGHT]: 'neutral',
  [CMS_THEME.DARK]: 'inverse',
  [CMS_THEME.NEUTRAL]: 'neutral',
} as const;

const variantToStyle = {
  btn: 'solid',
  'btn-outline': 'outlined',
  'btn-link': 'text',
} as const;

export function generateButtonClassName({
  cmsTheme,
  variant,
  size,
  width,
  className,
}: {
  cmsTheme: CMS_THEME;
  variant: 'btn' | 'btn-outline' | 'btn-link';
  size?: 'small' | 'medium' | 'large';
  width?: 'auto' | 'full';
  className?: (string | false | null | undefined)[]; // This is a subset of the tailwind-variants ClassValue type
}) {
  return buttonStyles({
    buttonColor: cmsThemeToColor[cmsTheme],
    buttonStyle: variantToStyle[variant],
    size,
    width,
    className,
  });
}

export const CmsButton = ({
  cmsTheme,
  variant = 'btn',
  size = 'medium',
  width = 'auto',
  children,
  ...props
}: CmsButtonProps) => {
  return (
    <Button
      buttonColor={cmsThemeToColor[cmsTheme]}
      buttonStyle={variantToStyle[variant]}
      size={size}
      width={width}
      {...props}
    >
      {children}
    </Button>
  );
};

/**
 * For use with ButtonLinks that are defined in the CMS with a theme. If not defined in the CMS,
 * prefer the `ButtonLink` component.
 */
export const CmsButtonLink = ({
  cmsTheme,
  variant = 'btn',
  className,
  children,
  width = 'auto',
  ...props
}: CmsButtonLinkProps) => {
  return (
    <Link
      className={generateButtonClassName({
        cmsTheme,
        variant,
        size: 'medium',
        width,
        className: [className, 'pds-text-center pds-no-underline'],
      })}
      {...props}
    >
      {children}
    </Link>
  );
};

import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { ImageProps } from 'react-bootstrap';

export interface IMediaVectorImage extends Media, JSX.IntrinsicAttributes, Omit<ImageProps, 'id'> {
  relationships: {
    svg: {
      url: string;
    };
  };
  svg: {
    alt: string;
  };
  image?: IGatsbyImageData;
}

const MediaVectorImage = function ({
  svg,
  relationships,
  internal,
  className,
  ...props
}: IMediaVectorImage) {
  const vectorImageSrc = relationships?.svg?.url;
  const vectorImageAlt = svg?.alt ?? '';

  if (!vectorImageSrc) {
    return null;
  }

  return (
    <img
      className={className}
      data-media-type={internal.type}
      src={vectorImageSrc}
      alt={vectorImageAlt}
      {...props}
    />
  );
};

export default MediaVectorImage;

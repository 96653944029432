import React from 'react';
import { StorageHeroCardStyles } from '../StorageHeroCardStyles';
import { IHeroStyleSettings } from '../types';

const HeroCardWrapper: React.FC<{
  children: React.ReactNode;
  styleSettings: IHeroStyleSettings;
}> = ({ children, styleSettings }) => {
  const { heroCardStyles, card } = StorageHeroCardStyles(styleSettings);
  return (
    <div className={heroCardStyles()}>
      <div className={card()}>{children}</div>
    </div>
  );
};

export default HeroCardWrapper;

import { pdsTv } from '@purinanbm/pds-ui';

export const imageContentStyles = pdsTv({
  slots: {
    imgContainer: 'pds-px-0 pds-py-5 md:pds-py-6 [&_img]:pds-max-w-full',
    wrapper: '',
    thumbnail: 'pds-mb-4 pds-rounded md:pds-mb-0',
    heading: 'pds-flex-column pds-justify-center',
  },
  variants: {
    verticalAlignment: {
      top: { wrapper: 'pds-items-start' },
      center: { wrapper: 'pds-items-center' },
      bottom: { wrapper: 'pds-items-end' },
    },
    // horizontal justification
    contentPosition: {
      left: { wrapper: '' },
      right: { wrapper: 'pds-flex-row-reverse' },
    },
    color: {
      dark: {
        imgContainer: 'pds-bg-[#1E1E1E] pds-text-white',
        richText: 'pds-prose-invert',
      },
      light: {
        imgContainer: 'pds-bg-white pds-text-[#2B2B2B]',
        richText: '',
      },
      neutral: { imgContainer: 'pds-bg-[#EDF0F8] pds-text-[#2B2B2B]', richText: '' },
    },
  },
  defaultVariants: {
    color: 'light',
    contentPosition: 'left',
    verticalAlignment: 'center',
  },
});

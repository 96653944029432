import { pdsTv } from '@purinanbm/pds-ui';

export const StorageHeroCardStyles = pdsTv({
  slots: {
    mainContainer: '',
    foregroundImg: 'pds-max-h-[250px] pds-max-w-[250px]',
    bgImg: 'pds-max-w-full',
    bgWrapper: 'pds-max-full pds-h-full',
    heroCardStyles: 'pds-w-full pds-bg-utils-transparent',
    foregroundImgWrapper: 'pds-flex pds-w-full pds-p-4',
    text: '',
    card: '',
    heroContent: [
      'pds-justify-center',
      'pds-relative lg:pds-absolute lg:pds-bottom-0 lg:pds-left-0 lg:pds-right-0 lg:pds-top-0',
      'overflow-hidden pds-flex pds-items-center pds-gap-4',
    ],
    imgContentContainer: 'pds-flex pds-w-full pds-items-center',
    textContainer: 'pds-p-4 lg:pds-p-0',
  },
  variants: {
    theme: {
      light: {
        heroCardStyles: 'pds-bg-surface',
        mainContainer: 'pds-bg-surface',
        textContainer: 'pds-text-neutral',
        text: 'pds-text-surface-text',
      },
      dark: {
        heroCardStyles: 'pds-bg-surface-text',
        mainContainer: 'pds-bg-surface-text',
        textContainer: 'pds-text-neutral-text',
        text: 'pds-text-neutral-text',
      },
      neutral: {
        heroCardStyles: 'pds-bg-surface',
        mainContainer: 'pds-bg-surface',
        textContainer: 'pds-text-neutral-text',
        text: 'pds-text-neutral-text',
      },
    },
    textDirection: {
      right: {
        // imgContentContainer: 'lg:pds-justify-end',
        imgContentContainer: 'lg:pds-flex-row',
        heroContent: '',
      },
      left: {
        // imageContentContainer: 'lg:pds-flex-row-reverse',
        imgContentContainer: 'lg:pds-flex-row-reverse',
        heroContent: '',
      },
    },
    foregroundAlignment: {
      left: {
        foregroundImgWrapper: 'pds-justify-start',
      },
      right: {
        foregroundImgWrapper: 'pds-justify-end',
      },
    },
    variant: {
      standard: {
        bgWrapper: 'pds-flex',
        bgImg: ['pds-w-full after:pds-content-none lg:pds-h-[600px]'],
        imgContentContainer: ['pds-h-full pds-items-center pds-justify-end pds-p-0'],
        heroCardStyles: 'pds-flex pds-h-full pds-flex-col pds-justify-center lg:pds-p-6',
      },
      background_hero: {
        bgImg: [
          'lg:pds-h-[600px]',
          'pds-relative',
          'after:pds-absolute',
          'after:pds-h-full after:pds-w-full after:pds-rounded-[inherit]',
          ' after:pds-content-[""]',
          'after:pds-left-0 after:pds-top-0',
        ],
        heroContent: [],
        textContainer: 'lg:pds-text-neutral-text',
        heroCardStyles: 'pds-bg-utils-transparent',
        imgContentContainer: ['lg:pds-max-w-[1446px] lg:pds-p-6'],
      },
      background_hero_content_card: {
        bgImg: ['after:pds-content-none lg:pds-h-[600px]'],
        imgContentContainer: ['pds-h-full pds-items-center pds-justify-end pds-p-0'],
        heroCardStyles: 'pds-flex pds-w-full pds-justify-end pds-bg-utils-transparent',
        card: 'pds-rounded-none pds-rounded-b pds-p-4 md:pds-rounded md:pds-p-5 lg:pds-m-5 lg:pds-max-w-[700px]',
      },
      short: {
        bgWrapper: 'pds-flex',
        bgImg: ['pds-w-full after:pds-content-none lg:pds-h-[400px]'],
        imgContentContainer: ['pds-h-full pds-items-center pds-justify-end pds-p-0'],
        heroCardStyles: 'pds-flex pds-h-full pds-flex-col pds-justify-center lg:pds-p-6',
      },
    },
    overlayTheme: {
      light: {
        bgImg: 'after:pds-bg-skrimLight',
      },
      dark: {
        bgImg: 'after:pds-bg-skrim',
      },
    },
  },
  /**
   * hero bg + direction changes the skrim
   * theme compounds
   **/
  compoundVariants: [
    {
      textDirection: 'left',
      variant: ['standard', 'short'],
      class: {
        bgWrapper: 'pds-flex-row-reverse',
      },
    },
    {
      overlayTheme: 'light',
      variant: 'background_hero',
      class: {
        textContainer: 'lg:pds-text-neutral',
      },
    },
    {
      theme: 'dark',
      variant: ['standard', 'short'],
      textDirection: 'left',
      class: {
        heroContent:
          'pds-bg-gradient-to-r pds-from-neutral pds-from-50%  pds-to-utils-transparent pds-to-50%',
      },
    },
    {
      theme: 'dark',
      variant: ['standard', 'short'],
      textDirection: 'right',
      class: {
        heroContent:
          'pds-bg-gradient-to-l pds-from-neutral pds-from-50%  pds-to-utils-transparent pds-to-50%',
      },
    },
    {
      theme: 'light',
      variant: ['standard', 'short'],
      textDirection: 'right',
      class: {
        heroContent:
          'pds-bg-gradient-to-l pds-from-neutral-text pds-from-50%  pds-to-utils-transparent pds-to-50%',
      },
    },
    {
      theme: 'light',
      variant: ['standard', 'short'],
      textDirection: 'left',
      class: {
        heroContent:
          'pds-bg-gradient-to-r pds-from-neutral-text pds-from-50%  pds-to-utils-transparent pds-to-50%',
      },
    },
    {
      theme: 'dark',
      variant: 'background_hero_content_card',
      class: {
        card: 'pds-bg-surface-text',
      },
    },
    {
      theme: 'light',
      variant: 'background_hero_content_card',
      class: { card: 'pds-bg-surface' },
    },
    {
      theme: 'neutral',
      variant: 'background_hero_content_card',
      class: { card: 'pds-bg-surface' },
    },
    {
      textDirection: 'right',
      variant: 'background_hero',
      class: { bgImg: 'after:pds-rotate-180' },
    },
  ],
});

import { ButtonRadio, ButtonRadioGroup } from '@purinanbm/pds-ui';
import React from 'react';

interface ImpersonateStorageProps {
  node: any;
  schema: {
    [key: string]: any[];
  };
  component: React.FC<any>;
}

export const ImpersonateStorage: React.FC<ImpersonateStorageProps> = ({
  node,
  schema,
  component,
}) => {
  const [state, setState] = React.useState({ ...node });

  const handleChange = (key: string, value: string) => {
    const clone = structuredClone(state);
    const traverse = (clonedState: any) => {
      // eslint-disable-next-line
      for (const stateKey in clonedState) {
        if (typeof clonedState[stateKey] === 'object') {
          traverse(clonedState[stateKey]);
        }
        if (stateKey === key) {
          // eslint-disable-next-line
          clonedState[stateKey] = value;
        }
      }
    };
    traverse(clone);
    setState(clone);
  };

  const getDefaultValue = (key: string) => {
    // eslint-disable-next-line
    let v;
    const t = (s: any) => {
      // eslint-disable-next-line
      for (const stateKey in s) {
        if (typeof s[stateKey] === 'object') {
          t(s[stateKey]);
        }
        if (stateKey === key) {
          // eslint-disable-next-line
          v = s[stateKey];
        }
      }
    };
    t(state);
    return v as any;
  };

  const Component = component;
  return (
    <>
      {/* eslint-disable-next-line */}
      <div className="pds-grid pds-grid-cols-auto-fit-100 pds-gap-4">
        {Object.keys(schema).map(key => (
          <div className="pds-mb-2">
            <span className="pds-text-md pds-font-bold">{key}</span>
            <ButtonRadioGroup
              defaultValue={getDefaultValue(key)}
              title={key}
              key={key}
              className="pds-flex pds-flex-col"
            >
              {schema[key].map(value => (
                <ButtonRadio
                  label={value}
                  value={value}
                  key={value}
                  onClick={() => handleChange(key, value)}
                />
              ))}
            </ButtonRadioGroup>
          </div>
        ))}
      </div>
      <Component node={state} />
    </>
  );
};

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Link from 'src/components/Link';
import PerksLayout from './3532';

const ReceiptHome = () => {
  return (
    <PerksLayout>
      <Container>
        <Row>
          <Col>Receipt home col 1</Col>
        </Row>
        <Row>
          <Col>Receipt home col 2</Col>
        </Row>
      </Container>
      <Link to="/es/invalid-page">Invalid page</Link>
    </PerksLayout>
  );
};

export default ReceiptHome;

import { CMS_OVERLAY, CMS_THEME } from 'src/common/enums';

export interface IHeroStyleSettings {
  theme: CMS_THEME;
  textDirection: 'left' | 'right';
  foregroundAlignment: 'left' | 'right';
  overlayTheme: CMS_OVERLAY;
  variant: 'background_hero' | 'standard' | 'short' | 'background_hero_content_card';
}

export const HeroDefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    hero_variant_type: 'standard',
    content_position: 'left',
    content_card: false,
    fluid: true,
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.DARK,
    load_eagerly: false,
  },
  entity_buttons: {
    primary_button: 'btn',
  },
};

import { pdsTv } from '@purinanbm/pds-ui/dist/common/pdsTv';
import { graphql, useStaticQuery } from 'gatsby';
import { useFlags, useLDClient } from 'gatsby-plugin-launchdarkly';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StorageReward from 'src/cms-components/StorageReward';
import Link from 'src/components/Link';
import BazaarvoiceWidget from 'src/components/bazaarvoice/BazaarvoiceWidget';
import PricespiderWidget from 'src/components/pricespider/PricespiderWidget';
import ProductSelectFormula from 'src/components/product/ProductSelectFormula';
import ProductStatus from 'src/components/product/ProductStatus';
import { NodeProductProps } from '../types';

interface Props {
  node: NodeProductProps;
  bvID: string;
  sku: string;
  productStatus:
    | (
        | TaxonomyTermStatus
        | {
            name: string;
            variant: AlertLevels;
            link: LinkProps;
          }
      )[]
    | null;
  brand: {
    url: string;
    title: string;
  } | null;
  relatedProducts: {
    edges: [
      {
        node: NodeProductProps;
      },
    ];
  };
  language: 'en' | 'es' | (string & {});
}

const buyNowButton = pdsTv({
  base: 'md:!pds-w-[350px]',
  variants: {
    sticky: {
      true: {
        base: 'pds-rounded-none md:pds-rounded',
        wrapper:
          'pds-fixed pds-bottom-0 pds-left-0 pds-right-0 pds-z-10 pds-w-full md:pds-relative',
      },
    },
  },
  slots: {
    wrapper: '',
  },
});

const ProductTitle: React.FC<Props> = ({
  node,
  productStatus,
  brand,
  relatedProducts,
  bvID,
  sku,
  language,
}) => {
  const { t } = useTranslation();
  const { buyNowButtonStickiness } = useFlags();
  const ldClient = useLDClient();

  const rewards = useStaticQuery<{
    allStorageRewardInfo: { edges: { node: IStorageReward }[] };
  }>(graphql`
    query allQueryRewards {
      allStorageRewardInfo {
        edges {
          node {
            ...StorageReward
          }
        }
      }
    }
  `);

  const rewardNode =
    rewards.allStorageRewardInfo.edges.length > 0
      ? rewards.allStorageRewardInfo.edges.find(({ node: reward }) => reward.langcode === language)
          ?.node
      : null;

  const trackToutCtaClick = () => {
    sendIt({
      event: 'select_content',
      eventParams: {
        cta_location: `PDP: ${node.title}`,
        content_type: 'PDP tool tip button',
        item_id: 'check it out',
        link_url: rewardNode?.link.uri || 'null',
      },
    });
  };

  const { base, wrapper } = buyNowButton({ sticky: Boolean(buyNowButtonStickiness) });

  return (
    <>
      {productStatus && <ProductStatus statuses={productStatus} />}
      <AnalyticsPoint
        type="module"
        label={node.title}
        typeLabel="product_title"
        as="div"
        className="p-lg-5 h-100 d-md-flex flex-md-column align-items-md-start justify-content-md-center"
      >
        <h1 className="pds-text-title-md">{node.title}</h1>
        {brand && (
          <p>
            {t('By')}&nbsp;
            <AnalyticsPoint
              as="span"
              type="component"
              label={brand.title}
              action="brand tout"
              typeLabel="product_brand_tout"
            >
              <Link to={brand.url}>{brand.title}</Link>
            </AnalyticsPoint>
          </p>
        )}
        <BazaarvoiceWidget
          attributes={{
            productID: bvID,
            widgetID: 'rating_summary',
          }}
        />
        {rewardNode && (
          <StorageReward className="pds-mt-4" node={rewardNode} onClick={trackToutCtaClick} />
        )}
        {relatedProducts?.edges.length > 0 && (
          <ProductSelectFormula node={node} products={relatedProducts.edges} />
        )}
        {sku && (
          <div
            className={wrapper()}
            onClickCapture={() => {
              ldClient?.track('button-click', { isMobile: window?.innerWidth < 768 });
              ldClient?.flush();
            }}
          >
            <PricespiderWidget title={node.title} sku={sku} className={base()} />
          </div>
        )}
      </AnalyticsPoint>
    </>
  );
};
export default ProductTitle;

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, SelectItem } from '@purinanbm/pds-ui';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import InputField from 'src/components/forms/new_components/InputField';
import SelectField from 'src/components/forms/new_components/SelectField';
import { getAddressStates } from 'src/utils/formHelpers';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().min(8).required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required(),
  state: Yup.string()
    .required('Please fill out a State.')
    .notOneOf(['default'], 'Please select a State.'),
});

type FormType = Yup.InferType<typeof schema>;

const DummyPage = () => {
  const methods = useForm<FormType>({
    defaultValues: {
      state: 'default',
      confirmPassword: '',
      email: '',
      name: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const [state, setState] = React.useState<string>('');
  const { handleSubmit, reset } = methods;

  const addressStates = getAddressStates();
  const stateOptions = [['default', 'Select a State'], ...addressStates];
  const onSubmit: SubmitHandler<FormType> = data => {
    console.log(data);
    setState(`submitted values: ${JSON.stringify(data)}`);
    reset();
  };

  return (
    <div className="pds-flex pds-min-h-screen pds-items-center pds-justify-center">
      <div className="pds-max-w-[876px] pds-rounded-[24px] pds-border pds-border-solid pds-border-surface-line pds-bg-surface pds-px-4.5 pds-pt-4.5 md-bt:pds-px-5 md-bt:pds-pt-5">
        {state}
        <FormProvider {...methods}>
          <form
            className="pds-flex pds-grid pds-grid-cols-12 pds-flex-col pds-gap-4 pds-px-4 pds-py-5"
            onSubmit={handleSubmit(onSubmit, (errors, data) => {
              console.log(errors);
              console.log(data);
            })}
          >
            <div className="pds-col-span-12 md-bt:pds-col-span-6">
              <InputField required name="name" label="Name" autoComplete="given-name" />
            </div>
            <div className="pds-col-span-12 md-bt:pds-col-span-6">
              <InputField name="email" label="Email" autoComplete="email" />
            </div>
            <div className="pds-col-span-12 md-bt:pds-col-span-6">
              <InputField
                name="password"
                type="password"
                label="Password"
                autoComplete="password"
              />
            </div>
            <div className="pds-col-span-12 md-bt:pds-col-span-6">
              <InputField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                autoComplete="password"
              />
            </div>
            <div className="pds-col-span-12">
              <SelectField name="state" label="State" autoComplete="address-level1">
                {stateOptions.map(([key, value]) => {
                  return (
                    <SelectItem value={key} key={key}>
                      {value}
                    </SelectItem>
                  );
                })}
              </SelectField>
            </div>
            <Button
              size="medium"
              buttonColor="neutral"
              className="pds-col-span-12 md-bt:pds-col-span-4"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default DummyPage;

import { cx } from '@linaria/core';
import Icon from '@mdi/react';
import { Button, Panel, PanelContent, PanelTrigger, pdsTv } from '@purinanbm/pds-ui';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { mdiClose, mdiMagnify, mdiMenu } from 'src/assets/icons/mdiIcons';
import { useAuth } from 'src/hooks/useAuth';
import { IGeoMenu } from 'src/utils/helpers';

import { getParagraph } from '../../utils/paragraphHelpers';
import BasicBlocks from '../blocks/BasicBlock';
import { NavigationBlockData } from '../blocks/NavigationBlockData';
import SkipLink from '../screen_reader/SkipLink';
import PupProfile from './components/PupProfile';
import SearchBarForm from './components/SearchBarForm';

const utilityNavStyles = pdsTv({
  slots: {
    desktopNavbar:
      'pds-bottom-auto pds-left-auto pds-right-auto pds-top-auto pds-z-10 pds-mx-auto pds-flex pds-h-[5.9375rem] pds-max-w-[1600px] pds-items-center !pds-justify-between pds-gap-3 !pds-p-4 pds-pb-0 [&_>div]:pds-relative',
    mobileNavbar: [
      'pds-fixed',
      'pds-w-full',
      '[&_>div.show]:!pds-block [&_>div>div]:pds-h-full [&_>div]:pds-h-full [&_>div]:!pds-pb-4',
      'pds-bottom-0 pds-left-0 pds-right-0 pds-top-[5.9375rem]',
      'pds-bg-surface',
      'pds-border-t pds-border-t-[#E6E6E6]',
      'pds-transition-[height] pds-duration-300 pds-ease-[ease]',
      '[&_.dropdown>a]:pds-px-3',
      '[&_.dropdown-menu.show]:!pds-relative [&_.dropdown-menu.show]:!pds-mt-1 [&_.dropdown-menu.show]:!pds-transform-none',
    ],
    header:
      'pds-m-auto pds-flex pds-h-[3rem] pds-w-full pds-max-w-[1600px] pds-items-center lg:pds-justify-end',
  },
});

const MENU_MODAL_ID = 'menuModal';

const MenuButton = React.forwardRef<
  HTMLButtonElement,
  {
    buttonType: 'open' | 'close';
    showMenu: boolean;
    onClick: () => void;
  }
>(({ showMenu, buttonType, onClick }, ref) => {
  return (
    <AnalyticsPoint
      ref={ref}
      as={Button}
      type="component"
      node={{
        id: '',
        name: 'hamburger',
        type: 'hamburger',
        drupal_id: '',
      }}
      additionalParams={{
        item_id: buttonType,
      }}
      eventLabel={buttonType}
      category="hamburger"
      action="mobile_menu_toggle"
      variant="link"
      aria-label={buttonType === 'open' ? 'Show menu' : 'Close menu'}
      aria-expanded={buttonType === 'open' ? showMenu : undefined}
      aria-haspopup={buttonType === 'open' ? 'dialog' : undefined}
      aria-controls={buttonType === 'open' ? MENU_MODAL_ID : undefined}
      onClick={onClick}
      className={cx(
        'rounded-pill d-lg-none text-decoration-none justify-content-center order-2',
        'js-track',
        buttonType === 'close' ? '!pds-px-4' : '!pds-px-0',
      )}
      buttonStyle="outlined"
      buttonColor="neutral"
      style={{
        height: '3rem',
        width: buttonType === 'open' && '3rem',
      }}
    >
      {buttonType === 'close' && <span className="d-inline-block me-2">Close</span>}

      <Icon path={buttonType === 'open' ? mdiMenu : mdiClose} size={1} aria-hidden />
    </AnalyticsPoint>
  );
});

interface HeaderProps {
  language: Languages;
  translations?: Translations;
  linkgroup: IGeoMenu;
  pagePath?: string;
}

const Header: React.FC<HeaderProps> = function ({ language, translations, linkgroup, pagePath }) {
  const { user } = useAuth();
  const [showSearch, setShowSearch] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const { enableUniversalProfileMenu = false } = useFlags();
  const breakPoint = useBreakpoint();
  const { t } = useTranslation();
  const searchButtonRef = React.useRef<HTMLButtonElement>(null);
  const searchFormMountedRef = React.useRef(false);

  // Ensure the menu gets closed when window is resized
  React.useEffect(() => {
    if (showMenu && breakPoint.lg) {
      setShowMenu(false);
    }
  }, [breakPoint.lg, showMenu]);

  React.useEffect(() => {
    const closeMenu: React.EventHandler<any> = (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowMenu(false);
      }
    };
    if (showMenu) {
      window.addEventListener('keydown', closeMenu);
    }

    if (showSearch) {
      searchFormMountedRef.current = true;
    } else if (searchFormMountedRef.current) {
      searchButtonRef.current?.focus();
      searchFormMountedRef.current = false;
    }
    return () => window.removeEventListener('keydown', closeMenu);
  }, [showMenu, showSearch]);

  const data = useStaticQuery(graphql`
    {
      allStorageHeader {
        edges {
          node {
            langcode
            relationships {
              theme: field_theme {
                ...TaxonomyTermThemes
              }
              headerTop: field_header_top {
                type: __typename
                ...ParagraphMenu
              }
              headerBottom: field_header_bottom {
                type: __typename
                ...ParagraphLinkedLogo
                ...ParagraphNavigation
                ...ParagraphSearchForm
              }
            }
          }
        }
      }
    }
  `);

  const headerFromCMS = data.allStorageHeader.edges.filter(
    ({ node }: { node: { langcode: string } }) => node.langcode === language,
  )[0];
  const headerTopParagraphs: [Paragraph] = headerFromCMS?.node.relationships?.headerTop;
  const headerBottomParagraphs: [Paragraph] = headerFromCMS?.node.relationships?.headerBottom;

  const navigation = headerBottomParagraphs.find(p => p.type === 'paragraph__navigation');
  const topMenu = headerTopParagraphs.find(p => p.type === 'paragraph__menu');
  const linkedLogo = headerBottomParagraphs.find(p => p.type === 'paragraph__linked_logo');
  const SmartAppBanner = pagePath ? (
    <BasicBlocks location={pagePath} data={NavigationBlockData()} />
  ) : null;

  if (!navigation || !topMenu || !linkedLogo) return null;

  const NavigationDesktopComponent = getParagraph({
    node: navigation,
    props: {
      showMenu: false,
      translations,
      linkgroup,
    },
  });

  const NavigationMobileComponent = getParagraph({
    node: navigation,
    props: {
      showMenu,
      translations,
      linkgroup,
    },
  });

  const TopMenuComponent = getParagraph({
    node: topMenu,
    props: { location: 'utility', translations, linkgroup },
  });

  const LinkedLogoComponent = getParagraph({
    node: linkedLogo,
  });

  const { desktopNavbar, mobileNavbar, header } = utilityNavStyles();

  return (
    <>
      <Helmet bodyAttributes={{ class: showMenu && 'pds-overflow-hidden' }} />

      <div className="pds-sticky pds-top-0 pds-z-[990] pds-border-b pds-border-b-surface-line pds-bg-surface zoom-400:pds-relative">
        <header role="banner">
          <SkipLink href="#mainContent" className="pds-absolute pds-left-3 pds-top-3">
            Skip to Main Content
          </SkipLink>
          {/* Utility Nav */}
          <div className="pds-hidden pds-bg-primary lg:pds-block">
            <div className={header()}>{TopMenuComponent}</div>
          </div>
          {headerBottomParagraphs && (
            <Navbar className={desktopNavbar()} aria-label={t('Main')}>
              <AnimatePresence>
                <div className="pds-flex pds-w-full pds-items-center pds-justify-between lg:pds-gap-[118px] [&_>div:first-child]:pds-flex-1 [&_>div:first-child]:lg-bt:pds-flex-none [&_>div:second-child]:lg-bt:pds-flex-1">
                  <div
                    data-showSearch={String(showSearch)}
                    className="pds-block data-[showSearch=true]:pds-hidden data-[showSearch=true]:lg-bt:pds-block"
                  >
                    {LinkedLogoComponent}
                  </div>
                  {showSearch ? (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="pds-flex pds-flex-1 pds-items-center pds-justify-end pds-gap-3 [&_>form]:pds-flex-1"
                    >
                      <SearchBarForm setShowSearch={setShowSearch} />
                      <Button
                        buttonColor="neutral"
                        buttonStyle="outlined"
                        aria-label={t('Close search')}
                        onClick={() => setShowSearch(false)}
                        className="pds-h-[52px]"
                      >
                        Close <Icon path={mdiClose} size={1} aria-hidden />
                      </Button>
                    </motion.div>
                  ) : (
                    <div className="pds-flex pds-justify-between pds-gap-3">
                      {NavigationDesktopComponent}
                      <Button
                        buttonColor="neutral"
                        buttonStyle="outlined"
                        aria-label={t('Show search')}
                        ref={searchButtonRef}
                        className="pds-h-[48px] pds-w-[48px] pds-rounded-circle pds-p-0 pds-text-black"
                        onClick={() => setShowSearch(true)}
                      >
                        <Icon path={mdiMagnify} size={1} aria-hidden />
                      </Button>
                      {language === 'en' && enableUniversalProfileMenu && (
                        <span
                          className={`[&_button]:pds-h-[48px] [&_button]:pds-w-[48px] ${
                            !user?.ansiraId && '[&_button]:md:pds-w-auto'
                          }`}
                        >
                          <PupProfile />
                        </span>
                      )}
                      <Panel open={showMenu}>
                        <PanelTrigger asChild>
                          <MenuButton
                            buttonType="open"
                            showMenu={showMenu}
                            onClick={() => setShowMenu(true)}
                          />
                        </PanelTrigger>
                        <PanelContent
                          options={{ fullWidth: true, animate: false }}
                          id={MENU_MODAL_ID}
                          aria-describedby={undefined}
                          aria-labelledby={undefined}
                          aria-label={t('Main menu')}
                          customHeader={
                            <div className="pds-flex pds-justify-end pds-px-3.5 pds-pb-4 pds-pt-4.5">
                              {LinkedLogoComponent}
                              <MenuButton
                                buttonType="close"
                                showMenu={showMenu}
                                onClick={() => setShowMenu(false)}
                              />
                            </div>
                          }
                        >
                          <nav
                            className={mobileNavbar({ className: 'pds-p-0' })}
                            aria-label={t('Main')}
                          >
                            {NavigationMobileComponent}
                          </nav>
                        </PanelContent>
                      </Panel>
                    </div>
                  )}
                </div>
              </AnimatePresence>
            </Navbar>
          )}
        </header>
        {!showMenu && SmartAppBanner && (
          <div className="pds-absolute pds-w-full">{SmartAppBanner}</div>
        )}
      </div>
    </>
  );
};

export default Header;

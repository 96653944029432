import { Button, Spinner } from '@purinanbm/pds-ui';
import * as React from 'react';

const buttonStyles = ['solid', 'outlined'] as const;
const buttonStates = ['', 'disabled', 'loading'] as const;
const buttonColors = ['primary', 'neutral', 'inverse'] as const;
const ButtonsStyleguide = () => {
  return (
    <div>
      <h1>Buttons</h1>
      <div className="pds-grid pds-grid-cols-3">
        <span>Normal state</span>
        <span>Disabled</span>
        <span>Loading</span>
        {buttonStyles.map(style =>
          buttonColors.map(color =>
            buttonStates.map(state => (
              <div
                className={
                  color === 'inverse'
                    ? 'pds-self-center pds-bg-neutral-layer pds-p-4'
                    : 'pds-self-center pds-p-4'
                }
              >
                <Button
                  buttonColor={color}
                  buttonStyle={style}
                  isLoading={state === 'loading'}
                  disabled={state === 'disabled'}
                >
                  {state === 'loading' && (
                    <Spinner
                      color={style === 'outlined' && color === 'primary' ? '#BA161D' : 'white'}
                      size={1}
                    />
                  )}
                  Label
                </Button>
              </div>
            )),
          ),
        )}
      </div>
    </div>
  );
};

export default ButtonsStyleguide;

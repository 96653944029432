import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CmsButtonLink } from 'src/components/button/CmsButton';
import { DefaultSettings } from '../types';

interface ImgContentButtonsProps {
  imageContent: IStorageImageContent;
}

const ImgContentButtons: React.FC<ImgContentButtonsProps> = ({ imageContent }) => {
  const settings = Object.assign(DefaultSettings, imageContent?.behaviors || {});
  const { element: HeadingElement } = settings.entity_heading;
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings.entity_buttons?.primary_button;
  return (
    <ButtonDiadWrapper>
      {imageContent.links?.map((linkObject, index) => {
        const handleGA = () => {
          const hasSecondaryButton = imageContent.links?.length! > 1;
          const primaryTitle = imageContent.links?.[0]?.title ?? '[not set]';
          const secondaryTitle = imageContent?.links?.[1]?.title ?? '[not set]';
          sendIt({
            event: 'module_interaction',
            eventParams: {
              component_id: hasSecondaryButton
                ? `primary_cta: ${primaryTitle},  secondary_cta: ${secondaryTitle}`
                : `primary_cta: ${primaryTitle}`,
              component_type: 'image_content_link',
              content_name: `${HeadingElement} / ${imageContent?.title}`,
            },
          });
        };
        return (
          <CmsButtonLink
            onClick={handleGA}
            analytics={false}
            key={index}
            variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
            cmsTheme={cmsTheme}
            to={linkObject.url}
            {...linkObject.options?.attributes}
          >
            {linkObject.title}
          </CmsButtonLink>
        );
      })}
    </ButtonDiadWrapper>
  );
};

export default ImgContentButtons;

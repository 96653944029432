import React from 'react';
import RichText from 'src/components/rich_text/RichText';
import { imageContentStyles } from '../styles';
import { DefaultSettings, ImageContentBase } from '../types';

interface ImgContentTextProps extends ImageContentBase {
  imageContent: IStorageImageContent;
  children: React.ReactNode;
}

const ImgContentText: React.FC<ImgContentTextProps> = ({
  styleSettings,
  imageContent,
  children,
}) => {
  const { heading } = imageContentStyles(styleSettings);
  const settings = Object.assign(DefaultSettings, imageContent?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  return (
    <div className={heading({ className: 'pds-flex pds-w-full pds-flex-col md:pds-w-1/2' })}>
      <HeadingElement className={headingStyle}>{imageContent.title}</HeadingElement>
      {imageContent.subtitle && (
        <SubheadingElement className={subheadingStyle}>{imageContent.subtitle}</SubheadingElement>
      )}
      {imageContent.body?.processed && (
        <RichText className="pds-mt-4" cmsTheme={cmsTheme} body={imageContent.body.processed} />
      )}
      {children}
    </div>
  );
};

export default ImgContentText;

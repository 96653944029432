import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

export const NotificationBlockData = () => {
  const data = useStaticQuery(graphql`
    {
      allBlockContentNotificationBlock {
        edges {
          node {
            field_visibility
            relationships {
              field_component {
                type: __typename
                ...StorageArticleCards
                ...StorageImageContent
                ...StorageHeroCard
                ...StorageManualCards
                ...StorageHighlights
                ...StorageProductCards
                ...StorageRichText
                ...StoragePageTitle
                ...StorageBanner
                ...StorageAnsiraProfileForm
                ...StorageIngredientsMap
                ...StorageBrandGrid
                ...StorageFaqSection
                ...StorageUnfilteredHtml
                ...StorageSubnav
                ...StorageCardGrid
                ...StorageReviews
                ...StorageCategoryCards
                ...StorageContactCards
                ...StorageVideo
                ...StorageSmartAppBanner
              }
            }
            langcode
          }
        }
      }
    }
  `);
  return useMemo(() => data.allBlockContentNotificationBlock.edges, [data]);
};

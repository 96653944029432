import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { StorageHeroCardStyles } from '../StorageHeroCardStyles';
import { IHeroStyleSettings } from '../types';

interface HeroDesktopForgroundImageProps {
  heroCard: IStorageHeroCard;
  lazyLoad: 'eager' | 'lazy';
  foregroundImage: IGatsbyImageData;
  styleSettings: IHeroStyleSettings;
}

const HeroDesktopForgroundImage: React.FC<HeroDesktopForgroundImageProps> = ({
  foregroundImage,
  heroCard,
  lazyLoad,
  styleSettings,
}) => {
  const { foregroundImgWrapper, foregroundImg } = StorageHeroCardStyles(styleSettings);
  return (
    <div
      className={foregroundImgWrapper({
        className: 'pds-hidden lg:pds-flex',
      })}
    >
      <GatsbyImage
        alt={heroCard.relationships.secondary_image.field_media_image.alt}
        image={foregroundImage}
        className={foregroundImg({
          className: '!pds-hidden pds-w-full pds-max-w-[500px] lg:!pds-block',
        })}
        objectFit="contain"
        imgClassName="pds-object-contain"
        loading={lazyLoad}
      />
    </div>
  );
};

export default HeroDesktopForgroundImage;

import React from 'react';
import { Container } from 'react-bootstrap';
import ImgContentButtons from './components/ImgContentButtons';
import ImgContentImage from './components/ImgContentImage';
import ImgContentText from './components/ImgContentText';
import { imageContentStyles } from './styles';
import { DefaultSettings } from './types';

interface StorageImageContentProps {
  node: IStorageImageContent;
}

const StorageImageContent: React.FC<StorageImageContentProps> = function ({ node: imageContent }) {
  const settings = Object.assign(DefaultSettings, imageContent?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const media_image = imageContent?.relationships?.image;

  const styleSettings = {
    color: cmsTheme,
    contentPosition: settings.entity_variant.content_position,
    verticalAlignment: settings.entity_variant.vertical_alignment,
  };

  const { wrapper, imgContainer } = imageContentStyles(styleSettings);
  return (
    <Container
      id={imageContent.component_id}
      data-id={imageContent.drupal_id}
      className={imgContainer()}
      fluid
    >
      <Container>
        <div className={wrapper({ className: 'pds-flex pds-flex-wrap pds-gap-5 md:pds-gap-6' })}>
          <ImgContentImage media_image={media_image} styleSettings={styleSettings} />
          <ImgContentText imageContent={imageContent} styleSettings={styleSettings}>
            {imageContent.links && imageContent.links?.length > 0 && (
              <ImgContentButtons imageContent={imageContent} />
            )}
          </ImgContentText>
        </div>
      </Container>
    </Container>
  );
};

export default StorageImageContent;

import React from 'react';
import RichText from 'src/components/rich_text/RichText';
import { getMedia } from 'src/utils/mediaHelpers';
import { StorageHeroCardStyles } from '../StorageHeroCardStyles';
import { HeroDefaultSettings, IHeroStyleSettings } from '../types';

interface HeroTextProps {
  heroCard: IStorageHeroCard;
  logoImage: MediaImage | MediaVectorImage;
  children: React.ReactNode;
  styleSettings: IHeroStyleSettings;
}

const HeroText: React.FC<HeroTextProps> = ({ styleSettings, heroCard, logoImage, children }) => {
  const settings = Object.assign(HeroDefaultSettings, heroCard?.behaviors || {});
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const { textContainer, text } = StorageHeroCardStyles(styleSettings);

  return (
    <div className={textContainer()}>
      {logoImage && (
        <>
          {getMedia({
            alt: '',
            media: logoImage,
            className: 'pds-max-h-7 [&_img]:pds-object-left',
            objectFit: 'contain',
            imgClassName: 'pds-object-contain',
            loading: 'lazy',
          })}
        </>
      )}
      <HeadingElement className={headingStyle}>{heroCard.title}</HeadingElement>
      {heroCard?.subtitle && (
        <SubheadingElement className={subheadingStyle}>{heroCard.subtitle}</SubheadingElement>
      )}
      {heroCard?.body?.processed && (
        <RichText body={heroCard.body.processed} className={text({ className: 'pds-mt-4' })} />
      )}
      {children}
    </div>
  );
};

export default HeroText;

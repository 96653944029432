import { PageProps, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Layout from 'src/components/Layout';
import Seo from 'src/components/Seo';
import RichText from 'src/components/rich_text/RichText';
import { useSearchDrupal } from 'src/hooks/useSearchDrupal';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from 'src/utils/metatagHelpers';
import ArticleAuthorListingBody from './components/ArticleAuthorListingBody';
import { DropdownSocialShare } from './components/DropdownSocialShare';

interface Image {
  gatsbyImage: IGatsbyImageData;
  publicUrl: string;
}

interface NodeAuthorProps extends NodeContent {
  name: string;
  job_title: string;
  image: ImageMetadata;
  relationships: {
    image?: Image;
  };
}

interface DataProps {
  node: NodeAuthorProps;
  translations: Translations;
}

const domain = process.env.GATSBY_SITE_URL;
const AuthorTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
  location,
}) {
  const { image = {} as Image } = node.relationships;
  const hasImage = !isEmpty(image);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const { t } = useTranslation();

  const sp = React.useMemo(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(location.search)) || {};
    if (queryParams.page) queryParams.page = String(Number(queryParams.page) - 1);
    return { ...queryParams, author: node.drupal_internal__nid, api_slug: 'articles' };
  }, [location.search, node.drupal_internal__nid]);

  const { data, error, isFetching } = useSearchDrupal(sp, node.langcode);

  const absoluteUrl = `${domain}${node.path?.alias}`;
  const hasArticles =
    !isFetching && !error && data?.search_results && data.search_results.length > 0;
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <section className="container-xxl pds-flex pds-flex-col pds-items-center pds-gap-4.5 pds-py-5.5 md:pds-flex-row-reverse md:pds-items-start md:pds-gap-5">
        {hasImage && (
          <div>
            <GatsbyImage
              image={image.gatsbyImage}
              className="pds-size-[150px] pds-max-w-fit md:pds-size-[400px]"
              imgClassName="pds-object-cover pds-rounded-circle"
              alt={node.image?.alt || ''}
              loading="eager"
            />
          </div>
        )}
        <Stack className="pds-max-w-[59.5rem] md:pds-gap-4.5">
          <h1 className="pds-m-0 pds-text-display-sm pds-font-normal">{node.name}</h1>
          <span className="pds-m-0 pds-text-title-sm">{node.job_title}</span>
          {node.body?.processed && (
            <RichText className="pds-mt-4.5 md:pds-mt-5" body={node.body?.processed} />
          )}
          <div className="pds-mt-4.5">
            <DropdownSocialShare
              label={t('Share this Author Bio')}
              shareUrl={absoluteUrl}
              emailSubjectLine={t('Thought you might want to check this out...')}
              classNames={{
                menu: 'pds-bg-surface pds-shadow-stronger',
              }}
              articleType="(not set)"
              analytics={{
                event: 'share',
                eventCategory: 'social share',
                eventParams: {
                  item_id: 'share this author bio',
                  article_type: 'author bio',
                  content_name: node.name,
                  content_type: 'author share',
                },
              }}
            />
          </div>
        </Stack>
      </section>
      {hasArticles && (
        <section className="pds-flex pds-bg-surface-foreground/medium">
          <ArticleAuthorListingBody
            serverData={data}
            data={data}
            error={error}
            title={t('Articles Written by {{name}}', { name: node.name })}
            isFetching={isFetching}
          />
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeAuthor(id: { eq: $id }, langcode: { eq: $language }) {
      drupal_internal__nid
      ...Metatag
      internal {
        type
      }
      job_title: field_job_title
      body {
        summary
        processed
      }
      langcode
      path {
        alias
      }
      name: field_display_name
      image: field_author_image {
        alt
        height
        width
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        image: field_author_image {
          gatsbyImage(width: 400, height: 400, placeholder: NONE)
          url
        }
      }
    }
    translations: allNodeAuthor(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AuthorTemplate;

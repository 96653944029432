import React from 'react';
import { ButtonLink } from 'src/components/Link';
import { getMedia } from 'src/utils/mediaHelpers';

type Props = {
  node: IStorageReward;
  className: string;
  onClick?: () => void;
};

const StorageReward = ({ node, onClick, className }: Props) => {
  const image = getMedia({
    media: node.relationships.image,
    loading: 'eager',
    alt: '',
    width: 31,
    height: 46,
    style: { height: '46px' },
  });

  return (
    <div
      className={`${className} pds-flex pds-items-start pds-gap-5 pds-rounded-md pds-bg-surface-layer pds-px-4.5 pds-py-4 md:pds-p-4.5`}
    >
      {image}

      <div className="pds-flex pds-flex-col pds-gap-4 xl:pds-flex-row xl:pds-items-center">
        <div className="pds-text-balance pds-text-body-md pds-font-bold">{node.copy}</div>

        <ButtonLink
          to={node.link.uri}
          buttonColor="neutral"
          buttonStyle="outlined"
          size="small"
          className="pds-max-w-fit pds-flex-[0_0_auto]"
          analytics={false}
          onClick={() => {
            onClick?.();
          }}
        >
          {node.link.title}
        </ButtonLink>
      </div>
    </div>
  );
};

export default StorageReward;

import { pdsTv } from '@purinanbm/pds-ui';
import React from 'react';
import { Container } from 'react-bootstrap';
import { CmsButtonLink } from 'src/components/button/CmsButton';
import { CMS_THEME } from '../common/enums';
import IconCard from '../components/icon_card/IconCard';
import RichText from '../components/rich_text/RichText';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';

type Props = {
  node: IStorageCategoryCards;
};

const styles = pdsTv({
  slots: {
    text: 'pds-flex pds-flex-col pds-items-center pds-justify-center',
    container: 'pds-py-5 md:pds-py-6',
    list: [
      'pds-mb-3 pds-mt-0 pds-p-0 pds-px-0 md:pds-mb-6',
      'pds-grid pds-justify-center pds-gap-2 md:pds-gap-5.5',
      'pds-grid-cols-[repeat(2,128px)] md:pds-grid-cols-[repeat(3,minmax(0,185px))]',
    ],
  },
  variants: {
    theme: {
      light: {
        text: 'pds-text-paper-text',
        container: 'pds-bg-white',
      },
      dark: {
        text: 'pds-text-white',
        container: 'pds-bg-[#1E1E1E]',
      },
      neutral: {
        text: 'pds-text-paper-text',
        container: 'pds-bg-surface-foreground',
      },
    },
    columns: {
      4: {
        list: 'xl:pds-grid-cols-[repeat(4,minmax(0,185px))]',
      },
      5: {
        list: 'xl:pds-grid-cols-[repeat(5,minmax(0,185px))]',
      },
      6: {
        list: 'xl:pds-grid-cols-[repeat(6,minmax(0,185px))]',
      },
    },
  },
});

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

function isColumnCount(columnCount: number): columnCount is 4 | 5 | 6 {
  return [4, 5, 6].includes(columnCount);
}

const StorageCategoryCards: React.FC<Props> = ({ node: categoryCards }) => {
  const settings = Object.assign(defaultSettings, categoryCards?.behaviors || {});
  const cmsColumnCount = Number(categoryCards.behaviors?.entity_rows_columns?.columns);
  const columns = isColumnCount(cmsColumnCount) ? cmsColumnCount : 4;
  const { title } = categoryCards;
  const richText = categoryCards?.text?.processed;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const iconMenu = categoryCards.relationships.icon_menu;
  const items = iconMenu?.relationships.field_menu_links;
  const cmsTheme = settings?.entity_variant?.theme;

  // If menu is draft, all items are draft, or menu items untranslated, don't render.
  if (!iconMenu || !items || !items.length) {
    // eslint-disable-next-line no-console
    console.error(
      `StorageCategoryCards: Missing menu or menu items for StorageCards component ${categoryCards.drupal_id}.`,
    );
    return null;
  }

  const { container, text, list } = styles({ theme: cmsTheme, columns });

  return (
    <Container
      id={categoryCards.component_id}
      role="group"
      aria-labelledby={`${categoryCards.drupal_id}-title`}
      data-id={categoryCards.drupal_id}
      fluid
      className={container()}
    >
      <Container fluid className={text()}>
        {title && (
          <HeadingElement
            id={`${categoryCards.drupal_id}-title`}
            className={`pds-mb-5 pds-text-center md:pds-mb-6 ${headingStyle}`}
            aria-label={title}
          >
            {title}
          </HeadingElement>
        )}

        {richText && (
          <RichText
            cmsTheme={cmsTheme}
            className="category-cards__body pds-mb-5.5"
            body={richText}
          />
        )}
      </Container>

      <ul className={list()}>
        {items
          .filter(item => item.link)
          .map(item => {
            const fieldMedia = item.relationships.field_media;
            const isIcon =
              'svg' in fieldMedia
                ? fieldMedia?.relationships?.svg?.filemime.search(/svg/i) > -1
                : fieldMedia?.relationships?.field_media_image?.filemime.search(/png/i) > -1;

            return (
              <li key={item.drupal_id} className="pds-list-none">
                <IconCard
                  drupalId={item.drupal_id}
                  cmsTheme={cmsTheme}
                  image={getMedia({
                    media: fieldMedia,
                    objectFit: isIcon ? 'contain' : 'cover',
                    alt: '',
                    objectPosition: isIcon
                      ? '50% 50%'
                      : getFocalPoint(item.relationships.field_media),
                  })}
                  to={item.link.url}
                  title={item.link.title}
                  {...item.link.options?.attributes}
                />
              </li>
            );
          })}
      </ul>

      {categoryCards.links?.[0] && (
        <CmsButtonLink
          cmsTheme={cmsTheme}
          to={categoryCards.links[0].url}
          className="pds-mx-auto pds-w-fit"
        >
          {categoryCards.links[0].title}
        </CmsButtonLink>
      )}
    </Container>
  );
};

export default StorageCategoryCards;
